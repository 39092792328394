import {Button, Label, TextInput} from 'flowbite-react';
import {gql, useMutation} from '@apollo/client';
import {useMemo, useState} from 'react';
import {capitalizeFirstLetter} from '../../../utils/FormatText';
import {checkTeacher} from './checkers';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';

const CREAT_TEACHER = gql`
    mutation createTeacher($name: String, $publishedAt: DateTime) {
        createTeacher(data: { name: $name, publishedAt: $publishedAt }) {
            data {
                attributes {
                    name
                    publishedAt
                }
            }
        }
    }
`;

export default function AddTeacher({cancel}) {
  const [newTeacher, setNewTeacher] = useState('');
  const [errorUpdate, setErrorUpdate] = useState('');
  const [createTeacher, {loading}] = useMutation(CREAT_TEACHER);

  const handleChange = (event) => {
    setNewTeacher(event.target.value);
    setErrorUpdate('');
  };

  const handleCancel = () => {
    setNewTeacher('');
    cancel();
  };

  const handleSave = async () => {
    const name = capitalizeFirstLetter(newTeacher.trim());
    setErrorUpdate('');

    const err = checkTeacher(name);
    if (err !== '') {
      setErrorUpdate(err);

      return;
    }

    try {
      await createTeacher({
        variables: {
          name: name,
          publishedAt: new Date(),
        },
        refetchQueries: ['teachers'],
      });
      setNewTeacher('');
    } catch (error) {
      setErrorUpdate(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setErrorUpdate(''); }, 3000);
    }
  };

  const isDisabled = useMemo(() => {
    return loading || errorUpdate !== '';
  }, [loading, errorUpdate]);

  return (
    <div className='w-[600px] mb-4'>
      <Label className='mb-2 block'>ФИО нового учителя</Label>
      <TextInput
        className='mb-4'
        value={newTeacher}
        onChange={handleChange}
        color={errorUpdate ? 'failure' : ''}
        helperText={errorUpdate !== '' && <span className='font-medium'> {errorUpdate} </span>}
      />
      <div className='flex gap-4'>
        <Button
          className='mt-6'
          color='gray'
          onClick={handleCancel}
        >
          Отмена
        </Button>
        <Button
          className='mt-6'
          onClick={handleSave}
          disabled={isDisabled}
          isProcessing={loading}
        >
          Добавить
        </Button>
      </div>
    </div>
  );
}

export function isOnlyLettersOrSpaces(str) {
  const regex = /^[a-zA-Z\-\u0400-\u04FF\s]+$/;
  return regex.test(str);
}

export function isOnlyRussianLettersOrSpaces(str) {
  const regex = /^[-\u0400-\u04FF\s]+$/;
  return regex.test(str);
}

export function isOnlyRussianLetters(str) {
  const regex = /^[\u0400-\u04FF]+$/;
  return regex.test(str);
}

export default function formatText(text) {
  // Удаляем начальные пробелы
  const trimmedValue = text.replace(/^\s+/g, '');

  // Оставляем только русские и английские символы, цифры, знаки препинания, / и \
  const sanitizedValue = trimmedValue.replace(/[^\wа-яёА-ЯЁ\d.,!?;:()"-/\\ ]/g, '');

  // Ограничиваем количество символов до 100
  return sanitizedValue.slice(0, 100);
}

export function capitalizeFirstLetter(string) {
  if (string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}


// Компонент для редактирования урока
import {Button} from 'flowbite-react';
import React, {useState} from 'react';
import {SelectSubject} from './SelectSubject';
import {SelectTeacher} from './SelectTeacher';
import {SelectClassRoom} from './SelectClassRoom';

export const EditLessonCard = ({lesson, onSave, onCancel}) => {
  const [formData, setFormData] = useState({
    id: lesson.id,
    subject: lesson.subject,
    subjectId: lesson.subjectId,
    teacher: lesson.teacher,
    teacherId: lesson.teacherId,
    room: lesson.room,
    roomId: lesson.roomId,
    timeStart: lesson.timeStart, // Должно быть добавлено в исходный объект lesson
    timeEnd: lesson.timeEnd,     // Должно быть добавлено в исходный объект lesson
  });

  const handleInputChanges = (values) => {
    setFormData({...formData, ...values});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onCancel();
  };

  return (
    <div className='flex flex-col gap-3 ml-5 mb-5'>
      <SelectSubject
        withTeacher={true}
        withClassRoom={true}

        defSubjectId={formData.subjectId}
        defTeacherId={formData.teacherId}
        defClassRoomId={formData.roomId}

        onChange={(selectedKey) => {
          handleInputChanges({
            'teacherId': selectedKey.teacherId ?? '',
            'subjectId': selectedKey.subjectId ?? '',
            'roomId': selectedKey.classRoomId ?? '',
          });
        }}
      />
      <SelectTeacher
        value={formData.teacherId}
        onChange={(value) => {
          handleInputChanges({'teacherId': value});
        }}
      />

      <SelectClassRoom
        value={formData.roomId}
        onChange={(value) => {
          handleInputChanges({'roomId': value});
        }}
      />

      <div className='flex justify-end gap-2'>
        <Button color='gray' onClick={onCancel}>Отмена</Button>
        <Button color='success' onClick={handleSubmit}>Сохранить</Button>
      </div>
    </div>
  );
};

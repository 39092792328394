import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import SelectInput from '../../components/SelectInput';
import {ErrorHandling} from '../../components/ErrorHandling';
import Loader from '../../components/Loader';

const CLASSES_LIST = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg = {}) {
        classes(filters: $filters, pagination: $pagination) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
                id
            }
        }
    }
`;

export const SelectClass = ({
  inputTitle,
  onChange,
}) => {
  const {data: classesData, error: errorClassesData, loading: loadingClassesData} = useQuery(CLASSES_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {
        limit: 500,
      },
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  const [classesList, setClassesList] = useState([]);

  useEffect(() => {
    if (!classesData?.classes?.data) {
      return;
    }

    const uniqueClassNames = new Set();

    classesData.classes.data.forEach(item => {
      uniqueClassNames.add(item?.attributes?.name);
    });

    const optionsSort = Array.from(uniqueClassNames)
      .sort((a, b) => Number(a) - Number(b))
      .map(num => ({
        value: num.toString(),
        label: num.toString(),
      }));

    optionsSort.unshift({
      value: '',
      label: 'Все параллели',
    });

    setClassesList(optionsSort);
  }, [classesData]);

  return (
    <>
      <ErrorHandling error={[errorClassesData]}/>
      {loadingClassesData && <Loader/>}

      <SelectInput
        onChange={(e) => onChange(e.target.value)}
        options={classesList}
        inputTitle={inputTitle}/>
    </>
  );
};

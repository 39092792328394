import React from 'react';
import {Table} from 'flowbite-react';

import ScrollingTextView from './ScrollingTextView';
import ReturnToMainView from './ReturnToMainView';
import SwipeTimeView from './SwipeTimeView';

export default function CommonList({schoolID}) {
  return (
    <>
      <Table striped>
        <Table.Head>
          <Table.HeadCell>Опция</Table.HeadCell>
          <Table.HeadCell>Значение</Table.HeadCell>
          <Table.HeadCell className='text-right'></Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          <ReturnToMainView schoolID={schoolID}/>
          <SwipeTimeView schoolID={schoolID}/>
          <ScrollingTextView schoolID={schoolID}/>
        </Table.Body>
      </Table>
    </>
  );
}

import {gql} from '@apollo/client';

export const SETTING_UPDATE = gql`
    mutation updateSetting($data: SettingInput!, $id: ID!) {
        updateSetting(data: $data, id: $id) {
            data {
                attributes {
                    name
                }
            }
        }
    }
`;

export const SETTING_CREATE = gql`
    mutation createSetting($data: SettingInput!) {
        createSetting(data: $data) {
            data {
                attributes {
                    name
                }
            }
        }
    }
`;

export const SETTINGS_LIST = gql`
    query settings($filters: SettingFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        settings(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    val
                }
                id
            }
        }
    }
`;
import {Select} from 'flowbite-react';
import React, {useEffect, useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {formatNameToInitials} from '../../utils/helpers';
import {ErrorHandling} from '../../components/ErrorHandling';
import Loader from '../../components/Loader';

const SUBJECTS_LIST = gql`
    query subjects($filters: SubjectFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        subjects(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes{
                    name
                    teachers{
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    classrooms{
                        data {
                            id
                            attributes{
                                number
                            }
                        }
                    }
                }
                id
            }
        }
    }
`;

function getId({
  subjectId,
  teacherId,
  classRoomId,
}) {
  return subjectId + '-' + teacherId + '-' + classRoomId;
}

export const SelectSubject = ({
  hasError,
  withTeacher,
  withClassRoom,
  defSubjectId,
  defTeacherId,
  defClassRoomId,
  onChange,
}) => {
  const [subjectList, setSubjectList] = useState([]);
  const [defId, setDefId] = useState({});
  const [subjectId, setSubjectId] = useState(defSubjectId);
  const [teacherId, setTeacherId] = useState(defTeacherId);
  const [classRoomId, setClassRoomId] = useState(defClassRoomId);

  const {data: subjectsResult, error: errorSubjects, loading} = useQuery(SUBJECTS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      sort: ['name'],
      pagination: {
        limit: 1000,
      },
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  useEffect(() => {
    setDefId(getId({
      subjectId,
      teacherId,
      classRoomId,
    }));
  }, [subjectId, teacherId, classRoomId]);

  useEffect(() => {
    if (!subjectsResult?.subjects?.data) {
      return;
    }

    let res = [];

    subjectsResult?.subjects?.data.forEach((subject) => {
      const id = subject.id;
      const name = subject.attributes.name.trim();

      let subjects = [];

      subjects.push({
        key: {
          subjectId: id,
          teacherId: undefined,
          classRoomId: undefined,
        },
        name,
      });

      if (withTeacher) {
        const subjectsWithTeachers = [];

        subject.attributes.teachers?.data.forEach((teacher) => {
          subjectsWithTeachers.push({
            key: {
              subjectId: id,
              teacherId: teacher.id,
              classRoomId: undefined,
            },
            name: name + ' - ' + formatNameToInitials(teacher.attributes.name),
          });
        });

        if (subjectsWithTeachers.length !== 0) {
          subjects = subjectsWithTeachers;
        }
      }

      if (withClassRoom) {
        const subjectsWithClassRooms = [];

        subjects.forEach((s) => {
          subject.attributes.classrooms?.data.forEach((classroom) => {
            subjectsWithClassRooms.push({
              key: {
                subjectId: s.key.subjectId,
                teacherId: s.key.teacherId,
                classRoomId: classroom.id,
              },
              name: s.name + ' - ' + classroom.attributes.number,
            });
          });
        });

        if (subjectsWithClassRooms.length !== 0) {
          subjects = subjectsWithClassRooms;
        }
      }

      res.push(...subjects);
    });

    setSubjectList(res.sort((a, b) => a.name.localeCompare(b.name)));
  }, [subjectsResult, withClassRoom, withTeacher]);

  return (
    <>
      {loading && <Loader/>}
      <ErrorHandling error={[errorSubjects]}/>

      {subjectList && (
        <Select
          color={hasError ? 'failure' : ''}
          value={defId}
          onChange={(e) => {
            if (e.target.value === '') {
              return;
            }
            const parts = e.target.value.trim().split('-');

            setSubjectId(parts[0]);
            setTeacherId(parts[1]);
            setClassRoomId(parts[2]);

            return onChange({
              subjectId: parts[0],
              teacherId: parts[1],
              classRoomId: parts[2],
            });
          }}
        >
          { (defId === 'undefined-undefined-undefined') && <option value=''>Выберете предмет</option>}
          {subjectList.map((subject) => (
            <option key={getId(subject.key)} value={getId(subject.key)}>
              {subject.name}
            </option>
          ))}
        </Select>
      )}
    </>
  );
};

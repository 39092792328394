export default function formatTime(timeString) {
  if (!timeString) {
    return '';
  }
  const [hours, minutes] = timeString.split(':');
  const formattedHours = parseInt(hours, 10);
  const formattedMinutes = parseInt(minutes, 10);

  // Добавляем нуль перед часами и минутами, если они < 10
  const formattedHoursWithLeadingZero = formattedHours < 10 ? `0${formattedHours}` : formattedHours;
  const formattedMinutesWithLeadingZero = formattedMinutes < 10 ? `0${formattedMinutes}` : formattedMinutes;

  return `${formattedHoursWithLeadingZero}:${formattedMinutesWithLeadingZero}`;
}

import React, {useState} from 'react';
import {Button, TextInput} from 'flowbite-react';
import {useMutation} from '@apollo/client';

import {ErrorNetworkDefault} from '../../../components/ErrorHandling';
import Loader from '../../../components/Loader';
import {SettingSwipeTime} from './SwipeTimeView';
import {SETTING_CREATE, SETTING_UPDATE} from './gql';


export default function EditSettingSwipeTime({id, value, onSave}) {
  const [newCommonError, setCommonError] = useState('');
  const [editValue, setValue] = useState(value);

  const [updateValue, {loading: loadingUpdateValue}] = useMutation(SETTING_UPDATE);
  const [createValue, {loading: loadingCreateValue}] = useMutation(SETTING_CREATE);

  const saveValue = async () => {
    setCommonError('');

    const newValue = +editValue;

    if (newValue < 1 || newValue > 360 || !Number.isInteger(newValue)) {
      setCommonError('Значение может быть не меньше 1 и не больше 360');
      return;
    }

    try {
      if (id) {
        await updateValue({
          variables: {
            id: id,
            data: {
              name: SettingSwipeTime,
              val: newValue.toString(),
            },
          },
          refetchQueries: ['settings'],
        });
      } else {
        await createValue({
          variables: {
            data: {
              name: SettingSwipeTime,
              val: newValue.toString(),
            },
          },
          refetchQueries: ['settings'],
        });
      }
      onSave();
    } catch (error) {
      setCommonError(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setCommonError(''); }, 3000);
    }
  };

  const handleValueChange = (event) => {
    setCommonError('');
    setValue(event.target.value);
  };

  return (
    <>
      {(loadingUpdateValue || loadingCreateValue) && (<Loader/>)}
      <div className='flex justify-between'>
        <TextInput
          className='mr-5'
          value={editValue}
          onChange={handleValueChange}
          placeholder='Время в секундах'
        />
        <Button onClick={saveValue}>Сохранить</Button>
      </div>
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError) && <span className='font-medium'> {newCommonError}</span>}
      </p>
    </>
  );
}

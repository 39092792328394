import React, {useEffect} from 'react';

export const ErrorNetworkDefault = 'Ошибка. Обновите страницу и/или попробуйте чуть позже.';

export function AlertErrorNetworkDefault(err) {
  alert(ErrorNetworkDefault + 'Error:' + err);
}

export const ErrorHandling = ({errors = []}) => {
  useEffect(() => {
    errors.forEach(error => {
      if (error) {
        console.error(error.message);

        if (error.message.includes('AccessDenied')) {
          localStorage.removeItem('token');
          localStorage.removeItem('schoolID');
          window.location.reload();
        }

      }
    });
  }, [errors]);

  if (errors.length === 0) {
    return null;
  }

  return (
    <>
      <p>Ошибка. Обновите страницу и/или попробуйте чуть позже.</p>;
      {errors.map((error, index) => (
        <div key={index}>{error}</div>
      ))}
    </>
  );
};

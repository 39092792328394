import React from 'react';
import {Tabs} from 'flowbite-react';

import TeacherList from './teacher/TeacherList';
import SubjectsList from './subject/SubjectsList';
import ClassesList from './class/ClassesList';
import ClassroomList from './classroom/ClassroomsList';
import ScheduleTime from './scheduletime/scheduletime';
import CommonList from './common/list';

export default function SettingsPage({schoolID}) {
  return (
    <div className='mt-0'>
      <Tabs aria-label='Pills' className='pills small-pills gap-4'>
        <Tabs.Item active title='Учителя'><TeacherList schoolID={schoolID}/></Tabs.Item>
        <Tabs.Item title='Звонки'><ScheduleTime schoolID={schoolID}/></Tabs.Item>
        <Tabs.Item title='Кабинеты'><ClassroomList schoolID={schoolID}/></Tabs.Item>
        <Tabs.Item title='Предметы'><SubjectsList schoolID={schoolID}/></Tabs.Item>
        <Tabs.Item title='Классы'><ClassesList schoolID={schoolID}/></Tabs.Item>
        <Tabs.Item title='Общие'><CommonList schoolID={schoolID}/></Tabs.Item>
      </Tabs>
    </div>
  );
}

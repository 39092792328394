import {Button, Select, TextInput} from 'flowbite-react';
import React, {useMemo, useState} from 'react';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import {capitalizeFirstLetter} from '../../../utils/FormatText';
import {checkLetter, checkParallel} from './checkers';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';

const UPDATE_CLASS = gql`
    mutation updateClass($data: ClassInput!, $id: ID!) {
        updateClass(data: $data, id: $id) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
            }
        }
    }
`;

const GET_CLASS = gql`
    query getClass($filters: ClassFiltersInput!) {
        classes(
            filters: $filters
            pagination: {
                limit: 1
            }
        ) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
                id
            }
        }
    }
`;

export default function EditClass({
  editClassRoomId,
  nameValue,
  letterValue,
  shiftValue,
  onClose,
}) {
  const [newCommonError, setNewCommonError] = useState('');

  const [newClass, setNewClass] = useState(nameValue);
  const [newClassError, setNewClassError] = useState('');

  const [newLetter, setNewLetter] = useState(letterValue);
  const [newLetterError, setNewLetterError] = useState('');

  const [newShift, setNewShift] = useState(shiftValue);

  const [updateClassroom, {loading}] = useMutation(UPDATE_CLASS);
  const client = useApolloClient();

  const resetErrors = () => {
    setNewCommonError('');
    setNewClassError('');
    setNewLetterError('');
  };

  const handleChange = (event) => {
    resetErrors();

    const {name, value} = event.target;

    switch (name) {
    case 'class':
      setNewClass(value);
      break;
    case 'letter':
      setNewLetter(value);
      break;
    case 'shift':
      setNewShift(value);
      break;
    default:
      break;
    }
  };

  const handleShiftChange = (event) => {
    resetErrors();

    setNewShift(event.target.value);
  };

  const handleSave = async () => {
    resetErrors();

    const newClassUpdated = Number(newClass).toString();
    let err = checkParallel(newClassUpdated);
    if (err !== '') {
      setNewClassError(err);
      return;
    }

    const newLetterUpdated = capitalizeFirstLetter(newLetter.trim());
    err = checkLetter(newLetterUpdated);
    if (err !== '') {
      setNewLetterError(err);
      return;
    }

    try {
      // проверить, что еще не создавали такой
      const {data: old} = await client.query({
        query: GET_CLASS,
        variables: {
          filters: {
            name: {eq: newClassUpdated},
            letter: {eq: newLetterUpdated},
            institution: {id: {eq: localStorage.getItem('schoolID')}},
          },
        },
        fetchPolicy: 'no-cache',
        skip: localStorage.getItem('schoolID') === '',
      });
      if (old?.classes?.data.length > 0 && old?.classes?.data[0]?.id !== editClassRoomId) {
        setNewCommonError('Такой класс уже существует');
        return;
      }

      await updateClassroom({
        variables: {
          id: editClassRoomId,
          data: {
            name: newClassUpdated,
            letter: newLetterUpdated,
            shift: newShift,
          },
        },
        refetchQueries: ['classes', 'schedules', 'scheduleTimes', 'scheduleNames'],
      });

      setNewClass('');
      setNewLetter('');
      onClose();
    } catch (error) {
      setNewCommonError(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setNewCommonError(''); }, 3000);
    }
  };

  const isDisabled = useMemo(() => {
    return loading || newClassError !== '' || newLetterError !== '';
  }, [loading, newClassError, newLetterError]);

  return (
    <div className='flex justify-between'>
      <div className='flex'>
        <TextInput
          name='class'
          className='w-[150px] mx-4'
          maxLength={2}
          color={newClassError ? 'failure' : ''}
          value={newClass}
          onChange={handleChange}
        />
        <TextInput
          name='letter'
          maxLength={6}
          className='w-[150px] mx-4'
          color={newLetterError ? 'failure' : ''}
          value={newLetter}
          onChange={handleChange}
        />
        <Select
          className='mx-4'
          name='shift'
          value={newShift}
          onChange={handleShiftChange}
        >
          <option value='first'>1</option>
          <option value='second'>2</option>
        </Select>
      </div>
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError || newClassError || newLetterError) &&
          <span className='font-medium'> {newCommonError} {newClassError} {newLetterError} </span>}
      </p>
      <Button
        onClick={handleSave}
        disabled={isDisabled}
        isProcessing={loading}
      >Сохранить</Button>
    </div>
  );
}


import {useMemo, useState} from 'react';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {CiCirclePlus} from 'react-icons/ci';
import { Badge } from 'flowbite-react';
import {gql, useMutation} from '@apollo/client';

import formatTime from '../../utils/FormatTime';
import {EditLessonCard} from './EditLessonCard';
import {InputLesson} from './InputLesson';
import Loader from '../../components/Loader';
import {formatNameToInitials} from '../../utils/helpers';

const UPDATE_SCHEDULE = gql`
    mutation updateSchedule(
        $id: ID!,
        $classroom: ID,
        $day: ENUM_SCHEDULE_DAY,
        $subject: ID,
        $teacher: ID,
        $schedule_time: ID
    ) {
        updateSchedule(
            id: $id
            data: {
                classroom: $classroom,
                day: $day,
                subject: $subject,
                teacher: $teacher,
                schedule_time: $schedule_time
            }
        ) {
            data {
                attributes {
                    day
                    teacher {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    classroom {
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                    subject{
                        data {
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

const DELETE_SCHEDULE = gql`
    mutation deleteSchedule($id: ID!) {
        deleteSchedule(id: $id) {
            data {
                id
            }
        }
    }
`;

function firstActual(arr) {
  if (arr.length === 0){
    return 0;
  }

  for (let i = 0; i<arr.length; i++){
    if (arr[i].attributes.timeStart !== '00:00:00.000' && arr[i].attributes.timeEnd !== '00:00:00.000') {
      return i;
    }
  }

  return 0;
}

function lastActual(arr) {
  if (arr.length === 0){
    return 0;
  }

  for (let i = arr.length-1; i>=0; i--){
    if (arr[i].attributes.timeStart !== '00:00:00.000' && arr[i].attributes.timeEnd !== '00:00:00.000') {
      return i;
    }
  }

  return 0;
}

export default function ScheduleCard({data, scheduleTimes, scheduleSaturdayTimes, scheduleName, loading, divWidth, arrHeight}) {
  const firstLesson = useMemo(()=>{
    return Math.min(firstActual(scheduleTimes), firstActual(scheduleSaturdayTimes));
  }, [scheduleTimes, scheduleSaturdayTimes]);

  const lastLesson = useMemo(()=>{
    return Math.max(lastActual(scheduleTimes), lastActual(scheduleSaturdayTimes));
  }, [scheduleTimes, scheduleSaturdayTimes]);

  const [editableLesson, setEditableLesson] = useState(null);
  const [showInputLesson, setShowInputLesson] = useState({});

  const handleAddLessonClick = (day, time) => {
    setShowInputLesson({[`${day}-${time}`]: true});
  };

  const [updateSchedule, {loading:loadingUpdateSchedule }] = useMutation(UPDATE_SCHEDULE);
  const [deleteSchedule, {loading:loadingDeleteSchedule }] = useMutation(DELETE_SCHEDULE);


  const handleEdit = (lesson) => {
    setEditableLesson(lesson);
  };

  const confirmDelete = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm('Вы уверены что хотите удалить?');
    if (!isConfirmed) {
      return;
    }

    try {
      await deleteSchedule({
        variables: {
          id: id,
        },
        refetchQueries: ['schedules', 'scheduleTimes', 'scheduleNames'],
      });
    } catch (e) {
      alert('Не удалось удалить: ' + e.toString());
    }
  };

  const handleSave = async (updatedLesson) => {
    try {
      await updateSchedule({
        variables: {
          ...updatedLesson,
          id: updatedLesson.id,

          subject: +updatedLesson.subjectId,
          teacher: +updatedLesson.teacherId,
          classroom: +updatedLesson.roomId,
        },
        refetchQueries: ['schedules', 'scheduleTimes', 'scheduleNames'],
      });
    } catch (error) {
      alert('Ошибка обновления расписания:' + error.toString());
      console.error('Ошибка обновления расписания:', error);
    }
    setEditableLesson(null);
  };

  const handleCancel = () => {
    setEditableLesson(null);
  };

  return (
    <>
      {(loadingUpdateSchedule || loadingDeleteSchedule) && <Loader/>}
      {data.map((daySchedule, dayIndex) => (
        <div
          className='schedule w-[460px] shadow-lg rounded overflow-hidden'
          key={daySchedule.date + String(dayIndex)}
        >
          {!loading && <div className='day-schedule'>
            <div className='flex justify-between items-center mb-4 bg-green-400 text-white p-4'>
              <div className='text-xl'>{daySchedule.day}</div>
              <div>{daySchedule.date}</div>
            </div>
            <div className='lessons px-4'>
              {(daySchedule.day === 'Суббота' ? scheduleSaturdayTimes : scheduleTimes).map((time, timeIndex) => {
                const line =  Math.floor(dayIndex / divWidth + 1);

                if (timeIndex < firstLesson || timeIndex > lastLesson || !arrHeight[line]) {
                  return <div key={`${dayIndex}-${timeIndex}`}></div>;
                }

                const lesson = daySchedule.lessons.filter(lesson => lesson.scheduleTimeId === time.id);
                const timeKey = `${daySchedule.day}-${formatTime(time.attributes.timeStart)}`;

                let h =  arrHeight[line][timeIndex]>0?arrHeight[line][timeIndex] * 100:100;

                return (
                  <div key={`${dayIndex}-${timeIndex}`} style={{minHeight: h}}>
                    <div className='lesson flex items-start justify-between'>
                      <div className='flex flex-row items-center gap-1'>
                        <Badge color='gray' className='text-xl text-center min-w-8 flex items-center justify-center m-1'>{timeIndex + 1}</Badge>
                        <div className='text-xs m-1'>
                          <div>{`${formatTime(time.attributes.timeStart)}`}</div>
                          {`${formatTime(time.attributes.timeEnd)}`}
                        </div>
                        {(!showInputLesson[timeKey] && time.attributes.timeStart !== '00:00:00.000') && lesson.length < 3 ?
                          <div
                            className='cursor-pointer m-1'
                            title='Добавить урок'
                            onClick={() => handleAddLessonClick(daySchedule.day, formatTime(time.attributes.timeStart))}>
                            <CiCirclePlus className='h-8 w-8 text-blue-400 transition hover:text-blue-800'/>
                          </div> : <div> <div className='m-1 h-8 w-8 transition'/></div>}
                      </div>
                      {lesson && lesson.length > 0 ? (
                        <div className='flex flex-col justify-center items-start flex-wrap w-full'>{
                          lesson.map((l, lIndex) => {
                            return (
                              <div
                                key={l.id}
                                className='flex grow items-stretch min-h-[73px] w-full'>
                                {editableLesson === l ? (
                                  <EditLessonCard
                                    lesson={editableLesson}
                                    onSave={handleSave}
                                    onCancel={handleCancel}
                                  />
                                ) : (
                                  <>
                                    <div className='flex items-center justify-center w-full grow text-center'>
                                      <div className='flex-col'>
                                        {
                                          lIndex !==0?
                                            <div className='flex justify-center' ><img width='35px' src={'/images/chain.svg'} alt={''}/></div>:
                                            <div></div>
                                        }
                                        <div className='text-xl font-bold text-center overflow-hidden overflow-ellipsis w-fit'>{l.subject}</div>
                                        <div className='text-sm'>{formatNameToInitials(l.teacher)}</div>
                                        <div className=''>{l.room}</div>
                                      </div>
                                    </div>
                                    <div className='flex flex-col'>
                                      {lIndex !==0? <div className='flex justify-center text-center w-full' ><div className='min-h-12'></div></div>:
                                        <div></div>}
                                      <div
                                        className='cursor-pointer hover:text-blue-800 transition'
                                        onClick={() => handleEdit(l)}
                                        title='Редактировать'>
                                        <RiEdit2Line/>
                                      </div>
                                      <div
                                        className='mt-4 cursor-pointer hover:text-red-500 transition'
                                        onClick={() => confirmDelete(l.id)}
                                        title='Удалить'><RiDeleteBinLine/>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className='flex grow items-stretch min-h-[73px] w-full'>
                          {!showInputLesson[timeKey] &&
                            <>
                              <div className='flex items-center justify-center w-full grow text-center'>
                                <div className='flex-col'>
                                  Нет урока
                                </div>
                              </div>
                              <div className='flex flex-col'>
                              </div>
                            </>
                          }
                        </div>
                      )}
                    </div>
                    {showInputLesson[timeKey] &&
                      <InputLesson
                        day={daySchedule.day}
                        timeObj={time}
                        onCancel={() => setShowInputLesson(false)}
                        onClose={() => setShowInputLesson(false)}
                        scheduleName={scheduleName}
                      />
                    }
                  </div>
                );
              })}
            </div>
          </div>}
        </div>
      ))}
    </>
  );
}

import {Button, Label, Textarea, TextInput} from 'flowbite-react';
import {gql, useMutation} from '@apollo/client';
import {useState} from 'react';

import formatText from '../../utils/FormatText';
import Loader from '../../components/Loader';

const UPDATE_MESSAGE = gql`
    mutation updateMessage($data: MessageInput!, $id: ID!) {
        updateMessage(data: $data, id: $id) {
            data {
                id
                attributes {
                    createdAt
                    description
                    publishedAt
                    title
                }
            }
        }
    }
`;
export default function EditMessageData({data, onCancel}) {
  const [formData, setFormData] = useState({
    title: data.newsTitle,
    description: data.newsDescr,
  });
  const [updateItem, {loading}] = useMutation(UPDATE_MESSAGE);

  const updateMessage = async () => {
    try {
      await updateItem({
        variables: {
          id: data.newsId,
          data: {
            description: formData.description,
            publishedAt: new Date().toISOString(),
            title: formData.title,
          },
        },
      });
      onCancel();
    } catch (e) {
      console.error('Ошибка в обновлении сообщения', e);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateMessage();
  };

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    const formattedValue = formatText(value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };

  return (
    <>
      {loading && <Loader/>}
      <form onSubmit={handleSubmit}>
        <div className='mb-8'>
          <div className='mb-2 block'>
            <Label htmlFor='title' value='Заголовок сообщения'/>
          </div>
          <TextInput
            maxLength={24}
            id='title'
            sizing='md'
            type='text'
            name='title'
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>
        <div className='mb-8'>
          <div className='mb-2 block'>
            <Label htmlFor='description' value='Текст сообщения'/>
          </div>
          <Textarea
            maxLength={150}
            id='description'
            rows={6}
            name='description'
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>
        <div className='flex justify-between'>
          <Button color='gray' onClick={onCancel}>
            Отмена
          </Button>
          <Button type='submit'>Сохранить</Button>
        </div>
      </form>
    </>
  );
}

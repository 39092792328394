import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Button} from 'flowbite-react';

import formatTimeForServer from '../../../utils/FormatTimeForServer';
import {ErrorHandling} from '../../../components/ErrorHandling';
import Loader from '../../../components/Loader';
import EditableTimeSlot from '../../schedule/EditableTimeSlot';

const CREATE_SCHEDULE_TIME = gql`
    mutation createScheduleTime($timeEnd: Time, $timeStart: Time, $callGrid: ENUM_SCHEDULETIME_CALLGRID, $day: ENUM_SCHEDULETIME_DAY) {
        createScheduleTime(data: {timeEnd: $timeEnd, timeStart: $timeStart, callGrid: $callGrid, day: $day}) {
            data {
                attributes {
                    timeStart
                    timeEnd
                }
                id
            }
        }
    }
`;

const SCHEDULE_TIMES = gql`
    query scheduleTimes($pagination: PaginationArg = {}, $filters: ScheduleTimeFiltersInput, $sort: [String] = []) {
        scheduleTimes(pagination: $pagination, filters: $filters, sort: $sort) {
            data {
                attributes {
                    timeStart,
                    timeEnd,
                    callGrid,
                    day
                }
                id
            }
        }
    }
`;

const UPDATE_SCHEDULE_TIME = gql`
    mutation updateScheduleTime($data: ScheduleTimeInput!, $id: ID!) {
        updateScheduleTime(data: $data, id: $id ) {
            data {
                attributes {
                    timeStart
                    timeEnd
                    callGrid
                }
                id
            }
        }
    }
`;

const globalInitialLessons = Array.from({length: 14}, (_, index) => ({
  id: `temp-${index}`,
  attributes: {
    timeStart: '00:00:00.000',
    timeEnd: '00:00:00.000',
  },
  name: `Урок ${index + 1}`,
}));

function timeToMinutes(time) {
  // Обработка пустых и неполных значений времени
  if (!time || typeof time !== 'string' || time.split(':').length < 2) {
    return Number.MAX_SAFE_INTEGER;
  }

  const [hours, minutes] = time.split(':').map(Number);

  return (hours * 60) + minutes;
}

function processScheduleData(scheduleData) {
  const lessons = scheduleData.scheduleTimes.data;
  const result = [...globalInitialLessons];

  lessons.forEach((lesson, index) => {
    if (index < result.length) {
      result[index] = {...lesson, name: `Урок ${index + 1}`};
    }
  });

  return result;
}

export default function ScheduleTime({schoolID}) {
  const [weekdayLessons, setWeekdayLessons] = useState(globalInitialLessons);
  const [saturdayLessons, setSaturdayLessons] = useState(globalInitialLessons);
  const [typeSchool, setTypeSchool] = useState('high_school');
  const [loadingWeekday, setLoadingWeekday] = useState(false);
  const [loadingSaturday, setLoadingSaturday] = useState(false);

  const [createScheduleTime, {error: errorCreate, loading: loadingCreateScheduleTime}] = useMutation(CREATE_SCHEDULE_TIME);
  const [updateScheduleTime, {error: errorUpdate, loading: loadingUpdateScheduleTime}] = useMutation(UPDATE_SCHEDULE_TIME);

  const {data: weekdayData, loading: loadingWeekdayData, error: errorWeekdayData} = useQuery(SCHEDULE_TIMES, {
    variables: {
      pagination: {
        limit: 1002,
      },
      filters: {
        day: {eq: 'Weekdays'},
        callGrid: {eq: typeSchool},
        institution: {id: {eq: schoolID}},
      },
      sort: ['id'],
    },
    skip: schoolID === '',
  });

  const {data: saturdayData, loading: loadingSaturdayData, error: errorSaturdayData} = useQuery(SCHEDULE_TIMES, {
    variables: {
      pagination: {
        limit: 1000,
      },
      filters: {
        day: {eq: 'Saturday'},
        callGrid: {eq: typeSchool},
        institution: {id: {eq: schoolID}},
      },
      sort: ['id'],
    },
    skip: schoolID === '',
  });

  useEffect(() => {
    if (!weekdayData) {
      return;
    }
    setWeekdayLessons(processScheduleData(weekdayData));
  }, [weekdayData]);

  useEffect(() => {
    if (!saturdayData) {
      return;
    }
    setSaturdayLessons(processScheduleData(saturdayData));
  }, [saturdayData]);

  const saveScheduleTimes = async (day) => {
    let hasError = '';
    const lessonsToSave = day === 'Weekdays' ? weekdayLessons : saturdayLessons;

    if (day === 'Weekdays') {
      setLoadingWeekday(true);
    } else {
      setLoadingSaturday(true);
    }

    // Проверяем, нет ли перекрытий времени
    for (let i = 1; i < lessonsToSave.length; i++) {
      const startCurrent = lessonsToSave[i].attributes.timeStart;
      const endPrevious = lessonsToSave[i - 1].attributes.timeEnd;
      // Пропуск проверки, если одно из полей времени пустое

      if (startCurrent === '00:00:00.000') {
        continue;
      }

      if (startCurrent && endPrevious && timeToMinutes(startCurrent) <= timeToMinutes(endPrevious)) {
        alert(`Ошибка: Время начала урока ${lessonsToSave[i].name} перекрывается с временем окончания урока ${lessonsToSave[i - 1].name}`);
        setLoadingWeekday(false);
        setLoadingSaturday(false);
        return;
      }
    }

    for (const lesson of [...lessonsToSave]) {
      // Проверяем, начинается ли id c temp. Temp - временный уникальный ID для каждого урока
      if (!String(lesson.id).startsWith('temp')) {
        if (lesson.attributes.timeStart === '' || lesson.attributes.timeEnd === '') {
          lesson.attributes.timeStart = '00:00:00.000';
          lesson.attributes.timeEnd = '00:00:00.000';
        }

        try {
          void updateScheduleTime({
            variables: {
              id: lesson.id,
              data: {
                timeStart: formatTimeForServer(lesson.attributes.timeStart),
                timeEnd: formatTimeForServer(lesson.attributes.timeEnd),
                day: day,
              },
            },
            refetchQueries: [],
          });
        } catch (error) {
          hasError = 'Ошибка при обновлении времени звонка' + error.toString();
          console.error('Ошибка при обновлении времени звонка', error);
        }

        continue;
      }

      try {
        // Создаем новое время
        await createScheduleTime({
          variables: {
            timeStart: formatTimeForServer(lesson.attributes.timeStart),
            timeEnd: formatTimeForServer(lesson.attributes.timeEnd),
            callGrid: typeSchool,
            day: day,
          },
          refetchQueries: [],
        });
      } catch (error) {
        hasError = 'Ошибка при обновлении времени звонка' + error.toString();
        console.error('Ошибка при обновлении времени звонка', error);
      }
    }

    setLoadingWeekday(false);
    setLoadingSaturday(false);
    if (hasError !== '') {
      alert('Ошибка при обновлении времени звонка: ' + hasError);
    }
  };

  const handleTimeChange = (lessonId, newTime, key) => {
    // Обновление времени звонка для соответствующего дня
    const updateLessons = (lessons, setLessons) => {
      setLessons(lessons.map(lesson => {
        if (lesson.id === lessonId) {
          return {...lesson, attributes: {...lesson.attributes, [key]: newTime}};
        }
        return lesson;
      }));
    };

    if (weekdayLessons.some(lesson => lesson.id === lessonId)) {
      updateLessons(weekdayLessons, setWeekdayLessons);
    } else if (saturdayLessons.some(lesson => lesson.id === lessonId)) {
      updateLessons(saturdayLessons, setSaturdayLessons);
    }
  };

  if (errorSaturdayData || errorWeekdayData) return <p>Ошибка загрузки. Обновите страницу и/или
    попробуйте чуть позже.</p>;

  return (
    <>
      { (loadingCreateScheduleTime || loadingUpdateScheduleTime || loadingWeekdayData || loadingSaturdayData) &&
        <Loader/>
      }
      <ErrorHandling error={[errorCreate, errorCreate, errorSaturdayData, errorWeekdayData, errorUpdate]}/>
      <div className='mb-10 w-full'>
        <div className='flex flex-wrap gap-5 mb-6'>
          <Button
            color={typeSchool === 'high_school' ? 'blue' : ''}
            onClick={() => setTypeSchool('high_school')}>
            Звонки для старшей школы
          </Button>
          <Button
            color={typeSchool === 'low_school' ? 'blue' : ''}
            onClick={() => setTypeSchool('low_school')}>
            Звонки для младшей школы
          </Button>
        </div>
      </div>
      <div className='overflow-x-auto w-full'>
        <div className='flex flex-wrap w-full min-w-[1280px]'>
          <div className='w-1/2'>
            <div className='text-xl font-bold mb-4'>Расписание на будние дни</div>
            {loadingWeekdayData && <Loader/>}
            {!loadingWeekdayData && weekdayLessons.map((lesson, index) => (
              <EditableTimeSlot
                key={lesson.id}
                index={index}
                lesson={lesson.attributes}
                onTimeChange={(newTime, key) => handleTimeChange(lesson.id, newTime, key, setWeekdayLessons)}
              />
            ))}
            <Button
              className='w-1/3 mt-10'
              size='lg'
              isProcessing={loadingWeekday}
              disabled={loadingWeekday}
              onClick={() => saveScheduleTimes('Weekdays')}>Сохранить</Button>
          </div>
          <div className='w-1/2'>
            <div className='text-xl font-bold mb-4'>Расписание на субботу</div>
            {loadingSaturdayData && <Loader/>}
            {!loadingSaturdayData && saturdayLessons.map((lesson, index) => (
              <EditableTimeSlot
                key={lesson.id}
                index={index}
                lesson={lesson.attributes}
                onTimeChange={(newTime, key) => handleTimeChange(lesson.id, newTime, key, setSaturdayLessons)}
              />
            ))}
            <Button
              className='w-1/3 mt-10'
              size='lg'
              isProcessing={loadingSaturday}
              disabled={loadingSaturday}
              onClick={() => saveScheduleTimes('Saturday')}>Сохранить</Button>
          </div>
        </div>
      </div>
    </>
  );
}

import {Button, Label, Select, TextInput} from 'flowbite-react';
import {gql, useApolloClient, useMutation, useQuery} from '@apollo/client';
import React, {useMemo, useRef, useState} from 'react';
import formatText from '../../../utils/FormatText';
import {ErrorHandling, ErrorNetworkDefault} from '../../../components/ErrorHandling';
import Loader from '../../../components/Loader';

const CREATE_SUBJECT = gql`
    mutation createSubject($data: SubjectInput!) {
        createSubject(data: $data) {
            data {
                attributes {
                    name,
                    classrooms {
                        data {
                            attributes {
                                name
                            }
                        }
                    },
                    teachers {
                        data {
                            attributes {
                                name
                            }
                        }
                    },
                }
            }
        }
    }
`;

// Список кабинетов
const CLASSROOMS_LIST = gql`
    query classrooms($filters: ClassroomFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        classrooms(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    number
                }
                id
            }
        }
    }
`;

// Список учителей
const TEACHERS_LIST = gql`
    query teachers($filters: TeacherFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        teachers(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                }
                id
            }
        }
    }
`;

const GET_SUBJECT = gql`
    query getSubject($filters: SubjectFiltersInput) {
        subjects(
            filters: $filters,
            pagination: {
                limit: 1
            }) {
            data {
                attributes {
                    name
                }
                id
            }
        }
    }
`;

const getSortedClassrooms = (list) => {
  return list?.slice().sort((a, b) => {
    let nameA = Number(a.attributes.number);
    let nameB = Number(b.attributes.number);

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    nameA = a.attributes.number.toUpperCase(); // для нечувствительности к регистру
    nameB = b.attributes.number.toUpperCase();

    return nameA.localeCompare(nameB);
  });
};

export default function AddSubject({cancel}) {
  const client = useApolloClient();
  const [newCommonError, setNewCommonError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [newSubject, setNewSubject] = useState('');
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [subjectLength, setSubjectLength] = useState(0);

  const teacherSelectRef = useRef(null);
  const classroomSelectRef = useRef(null);

  const [createSubject, {loading: loadingCreateSubject}] = useMutation(CREATE_SUBJECT);
  const {data: teacherData, error: errorTeacher, loading: loadingTeacherList} = useQuery(TEACHERS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {limit: 500},
      sort: ['name'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const {data: classroomData, error: errorClassrooms, loading: loadingClassRoomsList} = useQuery(CLASSROOMS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {
        limit: 500,
      },
      sort: ['number'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  const sortedClassrooms = useMemo(() => {
    if (!classroomData?.classrooms?.data) {
      return [];
    }

    return getSortedClassrooms(classroomData?.classrooms?.data);
  }, [classroomData]);

  const handleCancel = () => {
    setNewSubject('');
    cancel();
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatText(inputValue);

    setSubjectError(false);
    setSubjectLength(formattedValue.length);
    setNewSubject(formattedValue);
  };

  const handleTeacherChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const limitedSelectedTeachers = selectedOptions.slice(0, 5).map(option => option.value);

    setSelectedTeachers(limitedSelectedTeachers);
  };

  const handleClassroomChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const limitedSelectedClassrooms = selectedOptions.slice(0, 5).map(option => option.value);

    setSelectedClassrooms(limitedSelectedClassrooms);
  };

  const handleSave = async () => {
    setSubjectError('');
    setNewCommonError('');

    if (subjectLength < 2 || subjectLength > 75) {
      setSubjectError('Недопустимая длина названия предмета');
      return;
    }

    if (selectedClassrooms.length === 0) {
      setNewCommonError('Нужно выбрать хотя бы один кабинет');
      return;
    }

    if (selectedTeachers.length === 0) {
      setNewCommonError('Нужно выбрать хотя бы одного преподавателя');
      return;
    }

    try {
      const formattedSubject = newSubject.charAt(0).toUpperCase() + newSubject.slice(1);

      //проверка, что такого предмета еще не существует
      const {data: old} = await client.query({
        query: GET_SUBJECT,
        variables: {
          filters: {
            name: {
              eq: formattedSubject,
            },
            classrooms: {
              id: {
                in: selectedClassrooms,
              },
            },
            teachers: {
              id: {
                in: selectedTeachers,
              },
            },
            institution: {id: {eq: localStorage.getItem('schoolID')}},
          },
        },
        fetchPolicy: 'no-cache',
        skip: localStorage.getItem('schoolID') === '',
      });
      if (old?.subjects?.data.length > 0) {
        setNewCommonError('Предмет с одним из таких преподавателей или одним из кабинетов уже существует');
        return;
      }

      await createSubject({
        variables: {
          data: {
            name: formattedSubject,
            classrooms: selectedClassrooms,
            teachers: selectedTeachers,
            publishedAt: new Date(),
          },
        },
        refetchQueries: ['subjects'],
      });

      setNewSubject('');
    } catch (error) {
      setNewCommonError(ErrorNetworkDefault + ' Error:' + error);
    }
  };

  return (
    <div className='w-[400px] mb-4 '>
      { (loadingTeacherList || loadingClassRoomsList) && <Loader/> }
      <ErrorHandling error={[errorClassrooms, errorTeacher]}/>
      <Label className='mb-2 block'>Название предмета</Label>
      <TextInput
        className='mb-4'
        maxLength={75}
        color={subjectError ? 'failure' : ''}
        helperText={subjectError && (<span className='font-medium'>{subjectError}</span>)}
        onChange={handleChange}
        value={newSubject}
      />

      <div>
        <Label htmlFor='teacherSelect' className='mb-2 block'>Учитель</Label>
        <Select
          id='teacherSelect'
          ref={teacherSelectRef}
          multiple
          size='5'
          onChange={handleTeacherChange}
        >
          {teacherData?.teachers.data.map((teacher) => (
            <option key={teacher.id} value={teacher.id}>
              {teacher.attributes.name}
            </option>
          ))}
        </Select>
        <span className='text-xs'>Максимальное количество: 5</span>

        <Label htmlFor='classroomSelect' className='mb-2 block'>Кабинет</Label>
        <Select
          id='classroomSelect'
          multiple
          ref={classroomSelectRef}
          size='5'
          onChange={handleClassroomChange}
        >
          {sortedClassrooms.map((classroom) => (
            <option key={classroom.id} value={classroom.id}>
              {classroom.attributes.number} - {classroom.attributes.name}
            </option>
          ))}
        </Select>
        <span className='text-xs'>Максимальное количество: 5</span>
      </div>

      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError) && <span className='font-medium'> {newCommonError}</span>}
      </p>

      <div className='flex gap-4'>
        <Button
          className='mt-6'
          color='gray'
          onClick={handleCancel}
        >
          Отмена
        </Button>
        <Button
          className='mt-6'
          isProcessing={loadingCreateSubject}
          onClick={handleSave}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
}


import {gql, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {ErrorHandling} from './ErrorHandling';
import Loader from './Loader';

const INSTITUTION = gql`
    query institution($id: ID) {
        institution(id: $id) {
            data {
                attributes {
                    city {
                        data{
                            attributes{
                                name
                            }
                        }
                    }
                    name
                }
            }
        }
    }
`;

export default function Header({schoolID}) {
  const {data, error: errorInstitution, loading} = useQuery(INSTITUTION, {
    variables: {id: schoolID},
  });

  const [schoolName, setSchoolName] = useState('');
  const [schoolCity, setSchoolTitle] = useState('');

  useEffect(() => {
    if (data) {
      document.title = 'Система администрирования расписания: ' + data.institution.data.attributes.name;
      setSchoolName(data.institution.data.attributes.name);
      setSchoolTitle(data.institution.data.attributes.city.data.attributes.name);
    }
  }, [data]);
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('schoolID');
    window.location.reload();
  };

  return (
    <>
      {loading && <Loader/>}
      <ErrorHandling error={[errorInstitution]}/>
      <div className='bg-green-400 min-h-[100px] flex items-center mb-10'>
        <div className='container px-4 mx-auto flex items-center justify-between'>
          <div>{schoolName} {schoolCity && `, г. ${schoolCity}`}</div>
          <div className='cursor-pointer hover:opacity-75' onClick={logout}>Выйти</div>
        </div>
      </div>
    </>
  );
}

import {isOnlyRussianLettersOrSpaces} from '../../../utils/FormatText';

const minLetters = 6;
const maxLetters = 100;

export function checkTeacher(teacherName) {
  teacherName = teacherName.trim();

  if (teacherName.length < minLetters) {
    return 'Имя учителя должно быть длиннее ' + minLetters + ' символов';
  }

  if (teacherName.length > maxLetters) {
    return 'Имя учителя должно быть короче ' + maxLetters + ' символов';
  }

  if (!isOnlyRussianLettersOrSpaces(teacherName)) {
    return 'Имя учителя может содержать только русские буквы и пробелы';
  }

  return '';
}
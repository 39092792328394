import {Navigate, Route, Routes} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'));
    const checkToken = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    checkToken();
    window.addEventListener('storage', checkToken);

    return () => window.removeEventListener('storage', checkToken);
  }, []);

  return (
    <Routes>
      <Route
        path='/'
        element={isLoggedIn ? <HomePage/> : <Navigate to='/login'/>}
      />
      <Route
        path='/login'
        element={!isLoggedIn ? <LoginPage/> : <Navigate to='/'/>}
      />
    </Routes>
  );
}

export default App;

import React, {useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';
import {SETTINGS_LIST} from './gql';
import {Table} from 'flowbite-react';
import {RiEdit2Line} from 'react-icons/ri';
import ScrollingTextEdit, {
  SettingScrollingTextColors,
  SettingScrollingTextEnabled, SettingScrollingTextHeader, SettingScrollingTextMessage,
  SettingScrollingTextSpeed,
} from './ScrollingTextEdit';

function getSettings(data, settingName, def) {
  const settings = data?.settings?.data;
  if (!settings) {
    return def;
  }

  const foundItem = settings.find((setting => setting.attributes.name === settingName));
  if (foundItem === undefined) {
    return def;
  }

  return {
    id: foundItem.id,
    value: foundItem.attributes.val,
  };
}

export default function ScrollingTextView({schoolID}) {
  const [editingSettingScrollingTextEnabled, setEditingSettingScrollingTextEnabled] = useState(false);

  const {data, loading: loadingList, error} = useQuery(SETTINGS_LIST, {
    variables: {
      filters: { institution: { id: { eq: schoolID}} },
      pagination: {limit: 500},
    },
    skip: schoolID === '',
  });

  const settingScrollingTextEnabled = useMemo(()=>{
    return getSettings(data, SettingScrollingTextEnabled,  {
      id: undefined,
      value: false,
    });
  }, [data]);
  const settingScrollingTextColors = useMemo(()=>{
    return getSettings(data, SettingScrollingTextColors,  {
      id: undefined, value: 'BC1E4A/E1D798',
    });
  }, [data]);
  const settingScrollingTextSpeed = useMemo(()=>{
    return getSettings(data, SettingScrollingTextSpeed,  {
      id: undefined, value: '60',
    });
  }, [data]);
  const settingScrollingTextMessage = useMemo(()=>{
    return getSettings(data, SettingScrollingTextMessage,  {
      id: undefined, value: '',
    });
  }, [data]);
  const settingScrollingTextHeader = useMemo(()=>{
    return getSettings(data, SettingScrollingTextHeader,  {
      id: undefined, value: 'ВАЖНО',
    });
  }, [data]);

  return (
    <>
      {loadingList && (
        <Table.Row><Table.Cell className='text-black' colSpan={3}><Loader/></Table.Cell></Table.Row>
      )}
      {error && (
        <Table.Row><Table.Cell className='text-black' colSpan={3}><ErrorHandling error={[error]}/></Table.Cell></Table.Row>
      )}
      <Table.Row key='settingsScrollingTextView' className='bg-white dark:border-gray-700 dark:bg-gray-800 relative'>
        {editingSettingScrollingTextEnabled && <>
          <ScrollingTextEdit
            defScrollingTextEnabled={settingScrollingTextEnabled}
            defScrollingTextColors={settingScrollingTextColors}
            defScrollingTextSpeed={settingScrollingTextSpeed}
            defScrollingTextMessage={settingScrollingTextMessage}
            defScrollingTextHeader={settingScrollingTextHeader}

            onCancel={() => { setEditingSettingScrollingTextEnabled(false);}}/>
        </>}
        {!editingSettingScrollingTextEnabled && <>
          <Table.Cell className='text-black'><div >Показывать бегущую строку</div></Table.Cell>
          <Table.Cell className='w-[300px] text-xl text-black'>{settingScrollingTextEnabled.value==='1'?'Да':'Нет'}</Table.Cell>
          <Table.Cell className='w-[50px]'>
            <div className='flex gap-4 justify-end'>
              {!editingSettingScrollingTextEnabled && <RiEdit2Line
                className='cursor-pointer hover:text-blue-600 transition text-xl'
                title='Редактировать'
                onClick={() => { setEditingSettingScrollingTextEnabled(true);}}
              />}
            </div>
          </Table.Cell>
        </>}
      </Table.Row>
    </>
  );
}
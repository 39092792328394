import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Banner, Button, Label, Tabs, Textarea, TextInput} from 'flowbite-react';

import MessageCard from './MessageCard';
import formatText from '../../utils/FormatText';
import Loader from '../../components/Loader';
import {ErrorHandling} from '../../components/ErrorHandling';

const MESSAGES_LIST = gql`
    query messages($filters: MessageFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        messages(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes{
                    description
                    title
                    publishedAt
                }
                id
            }
        }
    }
`;
const CREATE_MESSAGES = gql`
    mutation createMessage($description: String!, $title: String!, $institution: ID, $publishedAt: DateTime) {
        createMessage(data: { description: $description, title: $title, institution: $institution, publishedAt: $publishedAt }) {
            data {
                attributes {
                    description
                    title
                }
            }
        }
    }
`;
const DELETE_MESSAGES = gql`
    mutation deleteMessage($id: ID!) {
        deleteMessage(id: $id) {
            data {
                id
                attributes {
                    title
                    description
                }
            }
        }
    }
`;

export default function MessagePage() {
  const [createMessage, {loading: loadingCreateMessage, error: errorCreateMessage}] = useMutation(CREATE_MESSAGES);
  const {data: messagesData, loading: messagesLoading, error: messagesError} = useQuery(MESSAGES_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {
        limit: 1000,
      },
      sort: ['publishedAt'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteMessage, {error: errorDeleteMessage, loading}] = useMutation(DELETE_MESSAGES);

  const [formState, setFormState] = useState({
    title: '',
    description: '',
    link: '',
    institution: localStorage.getItem('schoolID'),
  });
  const [messagesSuccessAdded, setMessagesSuccessAdded] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (messagesData?.messages?.data) {
      setMessages(messagesData.messages.data);
    }
  }, [messagesData]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    const formattedValue = formatText(value);

    setFormState((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleDeleteNews = async (newsId) => {
    try {
      await deleteMessage({
        variables: {id: newsId},
        refetchQueries: ['messages'],
      });
    } catch (e) {
      alert('Ошибка удаления сообщения:' + e.toString());
      console.error('Ошибка удаления сообщения:', e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createMessage({
        variables: {
          ...formState,
          publishedAt: new Date().toISOString(),
        },
        refetchQueries: ['messages'],
      });
      setFormState({
        title: '',
        description: '',
        institution: localStorage.getItem('schoolID'),
      });
      setMessagesSuccessAdded(true);
      setTimeout(() => {
        setMessagesSuccessAdded(false);
      }, 4000);
    } catch (e) {
      alert('Ошибка создания сообщения:' + e.toString());
      console.error('Ошибка создания сообщения:', e);
    }
  };

  if (messagesLoading) return <Loader/>;
  if (messagesError) return <p>Ошибка загрузки. Обновите страницу и/или попробуйте чуть позже.</p>;

  return (
    <div className='mt-0'>
      {loading && <Loader/>}
      <ErrorHandling error={[errorDeleteMessage, errorCreateMessage]}/>
      <Tabs
        aria-label='Pills'
        className='small-pills pills gap-4'
      >
        <Tabs.Item
          active
          title='Смотреть сообщения'
        >
          <MessageCard data={messages.map((newsItem) => ({
            newsId: newsItem.id,
            newsTitle: newsItem.attributes.title,
            newsDescr: newsItem.attributes.description,
            newsDate: newsItem.attributes.publishedAt,
          }))} deleteNews={handleDeleteNews}/>
        </Tabs.Item>
        <Tabs.Item
          active
          title='Добавить сообщение'
        >
          <div className='max-w-[600px]'>
            <form action='#' onSubmit={handleSubmit}>
              <div className='mb-8'>
                <div className='mb-2 block'>
                  <Label value='Заголовок сообщения*'/>
                </div>
                <TextInput
                  maxLength={24}
                  sizing='md'
                  type='text'
                  name='title'
                  value={formState.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className='mb-8'>
                <div className='mb-2 block'>
                  <Label value='Текст сообщения'/>
                </div>
                <Textarea
                  maxLength={150}
                  rows={6}
                  name='description'
                  value={formState.description}
                  onChange={handleInputChange}
                />
              </div>
              <p className='mb-4 text-gray-500 italic'>* - обязательное поле</p>
              <Button isProcessing={loadingCreateMessage}
                disabled={loadingCreateMessage || !formState.title.trim() || formState.title.trim().length < 5}
                onClick={handleSubmit}>Добавить сообщение</Button>
              {messagesSuccessAdded &&
                <Banner>
                  <div
                    className='fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 border-t border-gray-200 bg-green-400'>
                    <div className='flex items-center mx-auto text-white'>
                      <div className='text-2xl'>Сообщение успешно добавлено</div>
                    </div>
                  </div>
                </Banner>
              }
            </form>
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}

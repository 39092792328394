import {Button, Label, Radio, TextInput} from 'flowbite-react';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import {checkLetter, checkParallel} from './checkers';
import {capitalizeFirstLetter} from '../../../utils/FormatText';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';

const CREATE_CLASS = gql`
    mutation createClass($data: ClassInput!) {
        createClass(data: $data) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
            }
        }
    }
`;

const GET_CLASS = gql`
    query getClass($filters: ClassFiltersInput!) {
        classes(
            filters: $filters
            pagination: {
                limit: 1
            }
        ) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
                id
            }
        }
    }
`;

const shifts = [
  {id: 'first', label: 'Первая смена', value: 'first'},
  {id: 'second', label: 'Вторая смена', value: 'second'},
];

export default function AddClass({cancel}) {
  const [newCommonError, setNewCommonError] = useState('');

  const client = useApolloClient();
  const [newClass, setNewClass] = useState('');
  const [newClassError, setNewClassError] = useState('');

  const [newLetter, setNewLetter] = useState('');
  const [newLetterError, setNewLetterError] = useState('');

  const [newShift, setNewShift] = useState('first');

  const [createClass, {loading}] = useMutation(CREATE_CLASS);

  const resetErrors = () => {
    setNewCommonError('');
    setNewClassError('');
    setNewLetterError('');
  };

  const handleChange = (event) => {
    resetErrors();

    const {name, value} = event.target;

    switch (name) {
    case 'class':
      setNewClass(value);
      break;
    case 'letter':
      setNewLetter(value);
      break;
    case 'shift':
      setNewShift(value);
      break;
    default:
      break;
    }
  };

  const handleChangeShift = (event) => {
    resetErrors();

    setNewShift(event.target.value);
  };

  const handleCancel = () => {
    resetErrors();
    cancel();
  };

  const handleSave = async () => {
    const newClassUpdated = Number(newClass).toString();
    let err = checkParallel(newClassUpdated);
    if (err !== '') {
      setNewClassError(err);
      return;
    }

    const newLetterUpdated = capitalizeFirstLetter(newLetter.trim());
    err = checkLetter(newLetterUpdated);
    if (err !== '') {
      setNewLetterError(err);
      return;
    }

    try {
      // проверить, что еще не создавали такой
      const {data: old} = await client.query({
        query: GET_CLASS,
        variables: {
          filters: {
            name: {eq: newClassUpdated},
            letter: {eq: newLetterUpdated},
            institution: {id: {eq: localStorage.getItem('schoolID')}},
          },
        },
        fetchPolicy: 'no-cache',
        skip: localStorage.getItem('schoolID') === '',
      });
      if (old?.classes?.data.length > 0) {
        setNewCommonError('Такой класс уже существует');
        return;
      }

      await createClass({
        variables: {
          data: {
            name: newClassUpdated,
            letter: newLetterUpdated,
            shift: newShift,
            publishedAt: new Date(),
          },
        },
        refetchQueries: ['classes', 'schedules', 'scheduleTimes', 'scheduleNames'],
      });

      setNewClass('');
      setNewLetter('');
    } catch (error) {
      setNewCommonError(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setNewCommonError(''); }, 3000);
    }
  };

  const isDisabled = useMemo(() => {
    return loading || newClassError !== '' || newLetterError !== '';
  }, [loading, newClassError, newLetterError]);

  return (
    <div className='w-[400px] mb-4'>
      <Label className='mb-2 block'>Параллель</Label>
      <TextInput
        name='class'
        maxLength={2}
        className='mb-4'
        color={newClassError ? 'failure' : ''}
        value={newClass}
        onChange={handleChange}/>

      <Label className='mb-2 block'>Буква класса</Label>
      <TextInput
        name='letter'
        maxLength={6}
        className='mb-4'
        color={newLetterError ? 'failure' : ''}
        value={newLetter}
        onChange={handleChange}/>

      <div className='flex items-center gap-3'>
        {shifts.map((shift) => (
          <div key={shift.id}>
            <Radio id={shift.id} name='shift' value={shift.value} onChange={handleChangeShift}
              defaultChecked={shift.value === 'first'}/>
            <Label className='ml-2 cursor-pointer' htmlFor={shift.id}>{shift.label}</Label>
          </div>
        ))}
      </div>
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError || newClassError || newLetterError) &&
          <span className='font-medium'> {newCommonError} {newClassError} {newLetterError} </span>}
      </p>
      <div className='flex gap-4'>
        <Button
          className='mt-6'
          color='gray'
          onClick={handleCancel}
        >
          Отмена
        </Button>
        <Button
          className='mt-6'
          onClick={handleSave}
          disabled={isDisabled}
          isProcessing={loading}
        >
          Добавить
        </Button>
      </div>
    </div>
  );
}


import packageJson from '../../package.json';
import React, {useEffect, useState} from 'react';

const isElectron = () => {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` is set to false
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true;
  }

  return false;
};

export default function VersionAndDeveloper() {
  const [appVersion, setAppVersion] = useState('');

  useEffect(() => {
    if (isElectron()) {

      const {ipcRenderer} = window.require('electron');
      ipcRenderer.on('appVersion', (event, version) => {
        setAppVersion(version);
      });
      ipcRenderer.send('getAppVersion');

      return () => {
        ipcRenderer.removeAllListeners('appVersion');
      };
    } else {
      // Этот код будет выполняться вне Electron (например, в браузере)
      setAppVersion(''); // Установите здесь желаемую версию для браузера
    }
  }, []);

  return (
    <>
      <div className='developer-data-block'>
        <p>
          Проект компании <b>ВидноВсем</b>
        </p>
        <p>
          V{packageJson.version} {appVersion}
        </p>
      </div>
    </>
  );
}

import {Button, Label, TextInput} from 'flowbite-react';
import {gql, useApolloClient, useMutation} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import formatText from '../../../utils/FormatText';
import Loader from '../../../components/Loader';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';

const CREATE_CLASSROOM = gql`
    mutation createClassroom($data: ClassroomInput!) {
        createClassroom(data: $data) {
            data {
                attributes {
                    name
                    number
                    publishedAt
                }
            }
        }
    }
`;

const GET_CLASSROOM = gql`
    query getClassRoom($filters: ClassroomFiltersInput) {
        classrooms(
            filters: $filters,
            pagination: {
                limit: 1
            }) {
            data {
                attributes {
                    name
                    number
                }
                id
            }
        }
    }
`;

export default function AddClassroom({cancel}) {
  const [newClassroomNumber, setNewClassroomNumber] = useState('');
  const [newClassroomName, setNewClassroomName] = useState('');
  const [newClassroomNumberError, setNewClassroomNumberError] = useState('');
  const [newClassroomNameError, setNewClassroomNameError] = useState('');
  const [newCommonError, setNewCommonError] = useState('');
  const client = useApolloClient();
  const [createClassroom, {loading}] = useMutation(CREATE_CLASSROOM);

  const resetErrors = () => {
    setNewClassroomNumberError('');
    setNewClassroomNameError('');
    setNewCommonError('');
  };

  const handleChangeNumber = (event) => {
    resetErrors();
    const inputValue = formatText(event.target.value);
    setNewClassroomNumber(inputValue);
  };

  const handleChangeName = (event) => {
    resetErrors();
    const inputValue = event.target.value;
    setNewClassroomName(inputValue);
  };

  const handleCancel = () => {
    resetErrors();
    setNewClassroomName('');
    setNewClassroomNumber('');
    cancel();
  };

  const handleSave = async () => {
    resetErrors();

    if (!newClassroomNumber.trim()){
      setNewClassroomNumberError('Номер кабинета не может быть пустым');
      return;
    }

    if (!newClassroomName.trim()) {
      setNewClassroomNameError('Название кабинета не может быть пустым');
      return;
    }

    try {
      const {data: old} = await client.query({
        query: GET_CLASSROOM,
        variables: {
          filters: {
            number: {eq: newClassroomNumber.trim()},
            institution: {id: {eq: localStorage.getItem('schoolID')}},
          },
        },
        fetchPolicy: 'no-cache',
        skip: localStorage.getItem('schoolID') === '',
      });
      if (old?.classrooms?.data.length > 0) {
        setNewClassroomNumberError('Кабинет с таким номером уже существует');
        return;
      }

      await createClassroom({
        variables: {
          data: {
            name: newClassroomName.charAt(0).toUpperCase() + newClassroomName.slice(1),
            number: newClassroomNumber.trim(),
            publishedAt: new Date(),
          },
        },
        refetchQueries: ['classrooms'],
      });

      resetErrors();
    } catch (error) {
      setNewCommonError(ErrorNetworkDefault + ' Error:' + error);
      setTimeout(()=>{ setNewCommonError(''); }, 3000);
    }
  };

  const isDisabled = useMemo(() => {
    return loading || newClassroomNameError !== '' || newClassroomNumberError !== '' || newCommonError !== '';
  }, [loading, newClassroomNameError, newClassroomNumberError, newCommonError]);

  return (
    <div className='w-[400px] mb-4'>
      {loading && (<Loader/>)}
      <Label className='mb-2 block'>Номер кабинета</Label>
      <TextInput
        className='mb-4'
        maxLength={12}
        onChange={handleChangeNumber}
        value={newClassroomNumber}
        color={newClassroomNumberError ? 'failure' : ''}
        helperText={newClassroomNumberError && (<><span className='font-medium'>{newClassroomNumberError}</span></>)
        }
      />
      <Label className='mb-2 block'>Название кабинета <small className='block'>(например, кабинет
        Физики)</small></Label>
      <TextInput
        className='mb-4'
        onChange={handleChangeName}
        value={newClassroomName}
        color={newClassroomNameError ? 'failure' : ''}
        helperText={newClassroomNameError && (<><span className='font-medium'>{newClassroomNameError}</span></>)}
      />
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError) && <span className='font-medium'> {newCommonError}</span>}
      </p>
      <div className='flex gap-4'>
        <Button
          className='mt-6'
          color='gray'
          onClick={handleCancel}>Отмена
        </Button>
        <Button
          className='mt-6'
          isProcessing={loading}
          disabled={isDisabled}
          onClick={handleSave}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
}


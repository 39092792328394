// SchedulePage.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthProvider} from './components/AuthContext';
import WithApolloClient from './WithApolloClient';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <WithApolloClient>
          <App/>
        </WithApolloClient>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
);

// Если вы хотите начать измерять производительность в вашем приложении...
reportWebVitals();

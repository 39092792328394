import {isOnlyRussianLetters} from '../../../utils/FormatText';

const maxLetters = 10;

export function checkParallel(value) {
  if (value > 0 && value < 12) {
    return '';
  }

  return 'Параллель может быть с 1 по 11';
}

export function checkLetter(value) {
  value = value.trim();
  if (value.length === 0 || value.length > maxLetters) {
    return 'Буква класса должна быть обязательно указана и ее длина должна быть не больше ' + maxLetters;
  }

  if (!isOnlyRussianLetters(value)) {
    return 'Буква класса может содержать только русские буквы';
  }

  return '';
}
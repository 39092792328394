import {Button, TextInput} from 'flowbite-react';
import {gql, useMutation} from '@apollo/client';
import {capitalizeFirstLetter} from '../../../utils/FormatText';
import {useMemo, useState} from 'react';
import {checkTeacher} from './checkers';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';

const UPDATE_TEACHER = gql`
    mutation updateTeacher($data: TeacherInput!, $id: ID!) {
        updateTeacher(data: $data, id: $id) {
            data {
                attributes {
                    name
                }
            }
        }
    }
`;

export default function EditTeacher({teacherId, teacherName, onClose}) {
  const [editingTeacherName, setEditingTeacherName] = useState(teacherName);
  const [errorUpdate, setErrorUpdate] = useState('');
  const [updateTeacher, {loading}] = useMutation(UPDATE_TEACHER);

  const handleChange = (event) => {
    setEditingTeacherName(event.target.value);
    setErrorUpdate('');
  };

  const saveTeacher = async () => {
    const name = capitalizeFirstLetter(editingTeacherName.trim());
    setErrorUpdate('');

    const err = checkTeacher(name);
    if (err !== '') {
      setErrorUpdate(err);

      return;
    }

    try {
      await updateTeacher({
        variables: {
          id: teacherId,
          data: {
            name: name,
          },
        },
        refetchQueries: ['teachers'],
      });
      onClose();
    } catch (error) {
      setErrorUpdate(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setErrorUpdate(''); }, 3000);
    }
  };

  const isDisabled = useMemo(() => {
    return loading || errorUpdate !== '';
  }, [loading, errorUpdate]);

  return (
    <div className='flex justify-between'>
      <TextInput
        className='w-[400px] mr-4'
        value={editingTeacherName}
        onChange={handleChange}
        color={errorUpdate ? 'failure' : ''}
        helperText={errorUpdate !== '' && <span className='font-medium'> {errorUpdate} </span>}
      />
      <Button
        onClick={saveTeacher}
        disabled={isDisabled}
        isProcessing={loading}
      >Сохранить</Button>
    </div>
  );
}

import React, {createContext, useContext, useState} from 'react';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
  const [authToken, setAuthToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const setToken = (token) => {
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setAuthToken(token);
  };

  return (
    <AuthContext.Provider value={{authToken, setToken, isLoggedIn}}>
      {children}
    </AuthContext.Provider>
  );
};

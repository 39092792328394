import {RiLoader2Fill} from 'react-icons/ri';

export default function Loader() {
  return (
    <>
      <div
        className='fixed top-0 left-0 w-screen h-screen z-50 bg-white bg-opacity-10 flex items-center justify-center'>
        <RiLoader2Fill size='60px' className='animate-spin animate-slow'/>
      </div>
    </>
  );
}

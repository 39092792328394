import {Datepicker, Label, Select} from 'flowbite-react';

export default function SelectInput({
  inputTitle,
  options,
  required = true,
  datapicker = false,
  onChange,
  onSelectedDateChanged,
  minDate = new Date(),
}) {
  return (
    <div
      className='w-full max-w-[250px]'
    >
      <div className='mb-2 block text-gray-300'>
        {inputTitle && <Label value={inputTitle} className='select-label'/>}
      </div>
      {datapicker ?
        <Datepicker
          weekStart={2}
          labelClearButton='Очистить'
          labelTodayButton='Сегодня'
          showTodayButton={true}
          onSelectedDateChanged={(date) => onSelectedDateChanged(date)}
          language='ru-RU'
          minDate={minDate}/> :
        <Select
          onChange={onChange}
          required={required}
        >
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      }
    </div>
  );
}



import React, {useState} from 'react';
import ReactSelect from 'react-select';
import {useMutation} from '@apollo/client';
import Loader from '../../../components/Loader';
import {ErrorNetworkDefault} from '../../../components/ErrorHandling';
import {SETTING_CREATE, SETTING_UPDATE} from './gql';
import {Button, Checkbox, Table, TextInput} from 'flowbite-react';
import {MdClose} from 'react-icons/md';

const Label = ({bgColor1, bgColor2}) => (
  <div>
    <span style={{backgroundColor: bgColor1, fontWeight: 'bold', color: 'white', padding: '5px', marginRight: '0'}}>
      ВАЖНО
    </span>
    <span style={{backgroundColor: bgColor2, fontWeight: 'bold', color: 'black', padding: '5px', marginRight: '0'}}>
      Текст строки
    </span>
  </div>
);

const options = [
  {value: 'BC1E4A/E1D798', label: <Label bgColor1='#BC1E4A' bgColor2='#E1D798'/>},
  {value: 'F05623/D7D16E', label: <Label bgColor1='#F05623' bgColor2='#D7D16E'/>},
  {value: '008000/D3BCA3', label: <Label bgColor1='#008000' bgColor2='#D3BCA3'/>},
  {value: '2C75FF/C0C0C0', label: <Label bgColor1='#2C75FF' bgColor2='#C0C0C0'/>},
];

const optionsHeader = [
  {value: 'ВАЖНО', label: <div>ВАЖНО</div>},
  {value: 'ВНИМАНИЕ', label: <div>ВНИМАНИЕ</div>},
  {value: 'ОБЪЯВЛЕНИЕ', label: <div>ОБЪЯВЛЕНИЕ</div>},
  {value: 'НОВОСТЬ', label: <div>НОВОСТЬ</div>},
  {value: 'ИНФОРМАЦИЯ', label: <div>ИНФОРМАЦИЯ</div>},
  {value: 'СРОЧНО', label: <div>СРОЧНО</div>},
  {value: 'РАСПИСАНИЕ', label: <div>РАСПИСАНИЕ</div>},
  {value: 'СОБЫТИЕ', label: <div>СОБЫТИЕ</div>},
  {value: 'АНОНС', label: <div>АНОНС</div>},
  {value: 'КОНКУРС', label: <div>КОНКУРС</div>},
  {value: 'СЕМИНАР', label: <div>СЕМИНАР</div>},
  {value: 'ИЗМЕНЕНИЯ', label: <div>ИЗМЕНЕНИЯ</div>},
  {value: 'ПРАЗДНИК', label: <div>ПРАЗДНИК</div>},
  {value: 'КАНИКУЛЫ', label: <div>КАНИКУЛЫ</div>},
];


export const SettingScrollingTextEnabled = 'ScrollingTextEnabled';
export const SettingScrollingTextColors = 'ScrollingTextColors';
export const SettingScrollingTextSpeed = 'ScrollingTextSpeed';
export const SettingScrollingTextMessage = 'ScrollingTextMessage';
export const SettingScrollingTextHeader = 'ScrollingTextHeader';

export default function ScrollingTextEdit({
  defScrollingTextEnabled,
  defScrollingTextColors,
  defScrollingTextSpeed,
  defScrollingTextMessage,
  defScrollingTextHeader,
  onCancel,
}) {
  const [newCommonError, setCommonError] = useState('');
  const [updateValue, {loading: loadingUpdateValue}] = useMutation(SETTING_UPDATE);
  const [createValue, {loading: loadingCreateValue}] = useMutation(SETTING_CREATE);

  const [settingScrollingTextEnabled, setSettingScrollingTextEnabled] = useState(defScrollingTextEnabled);
  const [settingScrollingTextColors, setSettingScrollingTextColors] = useState(defScrollingTextColors);
  const [settingScrollingTextSpeed, setSettingScrollingTextSpeed] = useState(defScrollingTextSpeed);
  const [settingScrollingTextMessage, setSettingScrollingTextMessage] = useState(defScrollingTextMessage);
  const [settingScrollingTextHeader, setSettingScrollingTextHeader] = useState(defScrollingTextHeader);

  async function updateSettingScrollingText(name, value) {
    const id = value.id;
    const val = value.value;

    if (id) {
      await updateValue({ refetchQueries: ['settings'], variables: {id, data: {name, val}}});
    } else {
      await createValue({ refetchQueries: ['settings'], variables: {data: {name, val}}});
    }
  }

  const handleSave = async () => {
    setCommonError('');

    try {
      await updateSettingScrollingText(SettingScrollingTextEnabled, settingScrollingTextEnabled);
      await updateSettingScrollingText(SettingScrollingTextColors, settingScrollingTextColors);
      await updateSettingScrollingText(SettingScrollingTextSpeed, settingScrollingTextSpeed);
      await updateSettingScrollingText(SettingScrollingTextMessage, settingScrollingTextMessage);
      await updateSettingScrollingText(SettingScrollingTextHeader, settingScrollingTextHeader);

    } catch (error) {
      setCommonError(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setCommonError(''); }, 3000);
    }
  };

  return (
    <>
      <Table.Cell className='text-black' colSpan={2}>
        <div className='font-medium'>Редактирование бегущей строки</div>
        {/*-----------------------------------------------------------------*/}
        <div className='flex items-center  pt-4 w-[800px]'>
          <span className='mr-6'>Показывать бегущую строку: </span>
          <span>
            <Checkbox
              className='w-5 h-5 bg-white'
              defaultChecked={settingScrollingTextEnabled.value == '1'}
              onChange={(e) => {
                setSettingScrollingTextEnabled((state) => {
                  state.value = e.target.checked?'1':'0';
                  return state;
                });
              }}
            >
            </Checkbox>
          </span>
        </div>
        {/*-----------------------------------------------------------------*/}
        <div className='flex items-center pt-4'>
          <span className='mr-6 w-[210px]'>Цвета бегущей строки: </span>
          <span>
            <ReactSelect
              defaultValue={options.find((i) => i.value === settingScrollingTextColors.value)}
              className='mr-5 w-[300px]'
              onChange={(selected) => {
                setSettingScrollingTextColors((state) => ({ ...state, value: selected.value }));
              }}
              options={options}
            />
          </span>
        </div>
        {/*-----------------------------------------------------------------*/}
        <div className='flex items-center pt-4'>
          <span className='mr-6 w-[210px]'>Скорость строки: </span>
          <span>
            <TextInput
              className='mr-5'
              value={settingScrollingTextSpeed.value}
              onChange={(event) => {
                const { value } = event.target;
                if (value === '' || /^\d+$/.test(value)) {
                  setSettingScrollingTextSpeed((state) => ({ ...state, value }));
                }
              }}
              placeholder='Время в секундах'
            />
          </span>
        </div>
        {/*-----------------------------------------------------------------*/}
        <div className='flex items-center pt-4'>
          <span className='mr-6 w-[210px]'>Заголовок строки: </span>
          <span>
            <ReactSelect
              defaultValue={optionsHeader.find((i) => i.value === settingScrollingTextHeader.value)}
              className='mr-5 w-[300px]'
              onChange={(selected) => {
                setSettingScrollingTextHeader((state) => {
                  state.value = selected.value;
                  return state;
                });
              }}
              options={optionsHeader}
            />
          </span>
        </div>
        {/*-----------------------------------------------------------------*/}
        <div className='flex items-center pt-4'>
          <span className='mr-6 w-[210px]'>Сообщение в строке: </span>
          <span>
            <TextInput
              className='mr-5 w-[400px]'
              value={settingScrollingTextMessage.value}
              onChange={(event) => {
                const { value } = event.target;
                if (value.length <= 300) {
                  setSettingScrollingTextMessage((state) => ({ ...state, value }));
                }
              }}
              placeholder='Сообщение'
            />
          </span>
        </div>
        {/*-----------------------------------------------------------------*/}
        {(loadingUpdateValue || loadingCreateValue) && (<Loader/>)}
        <div className='flex mt-4'>
          <Button
            isProcessing={loadingUpdateValue || loadingCreateValue}
            onClick={handleSave}
          >Сохранить</Button>
          <Button
            className='ml-4'
            color='gray'
            disabled={loadingUpdateValue || loadingCreateValue}
            onClick={onCancel}
          >Отменить</Button>
        </div>
        <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
          {(newCommonError) && <span className='font-medium'> {newCommonError}</span>}
        </p>
      </Table.Cell>
      <Table.Cell className='w-[50px]'>
        <div className='flex gap-4 justify-end'>
          <MdClose className='cursor-pointer hover:text-blue-600 transition' title='Закрыть'
            onClick={onCancel}/>
        </div>
      </Table.Cell>
    </>
  );
}
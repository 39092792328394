export function formatNameToInitials(fullName) {
  if (!fullName) {
    return '';
  }

  const parts = fullName.trim().split(' ');

  if (parts.length > 2) {
    const [lastName, firstName, middleName] = parts;
    return `${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`;
  }

  if (parts.length === 2) {
    const [lastNameOnly, firstNameOnly] = parts;
    return `${lastNameOnly} ${firstNameOnly.charAt(0)}.`;
  }

  return parts[0];
}


import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Banner, Button, Label, Tabs, Textarea, TextInput} from 'flowbite-react';

import NewsCard from './NewsCard';
import formatText from '../../utils/FormatText';
import Loader from '../../components/Loader';
import {ErrorHandling} from '../../components/ErrorHandling';

const NEWS_LIST = gql`
    query news(
        $filters: NewFiltersInput
        $pagination: PaginationArg = {}
        $sort: [String] = []
    ) {
        news(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    link
                    description
                    title
                    publishedAt
                }
                id
            }
        }
    }
`;
const CREATE_NEWS = gql`
    mutation createNew(
        $description: String!
        $title: String!
        $link: String!
        $institution: ID
        $publishedAt: DateTime
    ) {
        createNew(
            data: {
                description: $description
                title: $title
                link: $link
                institution: $institution
                publishedAt: $publishedAt
            }
        ) {
            data {
                attributes {
                    link
                    description
                    title
                }
            }
        }
    }
`;
const DELETE_NEWS = gql`
    mutation deleteNew($id: ID!) {
        deleteNew(id: $id) {
            data {
                id
                attributes {
                    title
                    description
                }
            }
        }
    }
`;

export default function NewsPage() {
  const [createNews, {loading: loadingCreateNews, error: errorCreate}] = useMutation(CREATE_NEWS);
  const {
    data: newsData,
    loading: newsLoading,
    error: newsError,
  } = useQuery(NEWS_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: {
        limit: 1000,
      },
      sort: ['publishedAt'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteNews, {error: errorDelete, loading: loadingDeleteNews}] = useMutation(DELETE_NEWS);

  const [formState, setFormState] = useState({
    title: '',
    description: '',
    link: '',
    institution: localStorage.getItem('schoolID'),
  });
  const [news, setNews] = useState([]);
  const [newsSuccessAdded, setNewsSuccessAdded] = useState(false);
  const [emptyNews, setEmptyNews] = useState(false);

  useEffect(() => {
    if (newsData?.news?.data) {
      setNews(newsData.news.data);
    }
  }, [newsData]);

  const handleInputChange = (event) => {
    const {name, value} = event.target;

    const formattedValue = formatText(value);

    setFormState((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleDeleteNews = async (newsId) => {
    try {
      await deleteNews({
        variables: {id: newsId},
        refetchQueries: ['news'],
      });
    } catch (e) {
      alert('Ошибка удаления новости:' + e.toString());
      console.error('Ошибка удаления новости:', e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formState.link || !formState.title) {
      setEmptyNews(true);
      return;
    }
    setEmptyNews(false);

    try {
      await createNews({
        variables: {
          ...formState,
          publishedAt: new Date().toISOString(),
        },
        refetchQueries: ['news'],
      });
      setFormState({
        title: '',
        description: '',
        link: '',
        institution: localStorage.getItem('schoolID'),
      });

      setNewsSuccessAdded(true);
      setTimeout(() => {
        setNewsSuccessAdded(false);
      }, 4000);
    } catch (e) {
      alert('Ошибка создания новости:' + e.toString());
      console.error('Ошибка создания новости:', e);
    }
  };

  if (newsLoading) return <Loader/>;
  if (newsError)
    return (
      <p>Ошибка загрузки. Обновите страницу и/или попробуйте чуть позже.</p>
    );

  return (
    <div className='mt-0'>
      {loadingDeleteNews && <Loader/>}
      <ErrorHandling error={[errorDelete, errorCreate, newsError]}/>
      <Tabs aria-label='Pills' className='small-pills pills gap-4'>
        <Tabs.Item active title='Смотреть новости'>
          <NewsCard
            news={news.map((newsItem) => ({
              newsId: newsItem.id,
              newsTitle: newsItem.attributes.title,
              newsDescr: newsItem.attributes.description,
              newsLink: newsItem.attributes.link,
              newsDate: newsItem.attributes.publishedAt,
            }))}
            deleteNews={handleDeleteNews}
          />
        </Tabs.Item>
        <Tabs.Item active title='Добавить новость'>
          <div className='max-w-[600px]'>
            <form action='#' onSubmit={handleSubmit}>
              <div className='mb-8'>
                <div className='mb-2 block'>
                  <Label value='Название новости*'/>
                </div>
                <TextInput
                  sizing='md'
                  type='text'
                  name='title'
                  value={formState.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className='mb-8'>
                <div className='mb-2 block'>
                  <Label value='Ссылка*'/>
                </div>
                <TextInput
                  sizing='md'
                  type='text'
                  name='link'
                  value={formState.link}
                  onChange={handleInputChange}
                />
              </div>
              <div className='mb-8'>
                <div className='mb-2 block'>
                  <Label value='Текст новости'/>
                </div>
                <Textarea
                  rows={6}
                  name='description'
                  value={formState.description}
                  onChange={handleInputChange}
                />
              </div>
              {emptyNews && (
                <p className='text-red-700 my-4'>
                  Заполните поля со звездочкой
                </p>
              )}
              <Button
                isProcessing={loadingCreateNews}
                disabled={loadingCreateNews}
                onClick={handleSubmit}
              >
                Добавить новость
              </Button>
              <p className='my-4 italic'>* Поля обязательные для заполнения!</p>
              {newsSuccessAdded && (
                <Banner>
                  <div
                    className='fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 border-t border-gray-200 bg-green-400'>
                    <div className='flex items-center mx-auto text-white'>
                      <div className='text-2xl'>Новость успешно добавлена</div>
                    </div>
                  </div>
                </Banner>
              )}
            </form>
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}

import {gql, useMutation, useQuery} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import {Button, Modal, Table, Badge} from 'flowbite-react';
import {MdAdd, MdClose} from 'react-icons/md';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {HiOutlineExclamationCircle} from 'react-icons/hi';

import EditClass from './EditClass';
import AddClass from './AddClass';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';

const CLASSES_LIST = gql`
    query classes($schoolID: ID) {
        classes(
            filters: {
                institution: {
                    id: {
                        eq: $schoolID
                    }
                }
            }
            sort: ["name", "letter"]
            pagination: {
                limit: 500
            }
        ) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
                id
            }
        }
    }
`;

const DELETE_CLASS = gql`
    mutation updateClass($data: ClassInput!, $id: ID!) {
        updateClass(data: $data, id: $id) {
            data {
                attributes {
                    name
                    letter
                    shift
                }
            }
        }
    }
`;

export default function ClassesList({schoolID}) {
  const [showAdd, setShowAdd] = useState(false);
  const [editClassRoomId, setEditClassRoomId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editLetter, setEditLetter] = useState('');
  const [editShift, setEditShift] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);

  const {data, loading, error} = useQuery(CLASSES_LIST, {
    variables: {schoolID: schoolID},
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteClassroom, {loading: loadingDeleteClassroom}] = useMutation(DELETE_CLASS);

  const sortedClasses = useMemo(() => {
    return data?.classes?.data?.slice().sort((a, b) => {
      const nameA = Number(a.attributes.name);
      const nameB = Number(b.attributes.name);

      if (nameA > nameB) {
        return 1;
      }

      if (nameA < nameB) {
        return -1;
      }

      if (a.attributes.letter > b.attributes.letter) {
        return 1;
      }

      if (a.attributes.letter < b.attributes.letter) {
        return -1;
      }

      if (a.attributes.shift > b.attributes.shift) {
        return 1;
      }

      if (a.attributes.shift < b.attributes.shift) {
        return -1;
      }

      return 0;
    });
  }, [data?.classes?.data]);

  const startEdit = (classData) => {
    if (classData === null) {
      setEditClassRoomId(null);
      return;
    }

    setEditClassRoomId(classData?.id);
    setEditName(classData?.attributes?.name);
    setEditLetter(classData?.attributes?.letter);
    setEditShift(classData?.attributes?.shift);
  };

  const deleteRoom = async (subject) => {
    try {
      await deleteClassroom({
        variables: {
          id: subject,
          data: {
            publishedAt: null,
          },
        },
        refetchQueries: ['classes'],
      });
      setOpenDeleteModal(false);
    } catch (error) {
      alert('Ошибка при удалении класса:' + error.toString());
      console.error('Ошибка при удалении класса', error);
      setOpenDeleteModal(false);
    }
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  if (loading) return <Loader/>;

  return (
    <>
      {loadingDeleteClassroom && <Loader/>}
      <ErrorHandling error={[error]}/>
      {!showAdd &&
        <Button className='mb-6' onClick={() => setShowAdd(true)}>
          Добавить новый класс
          <MdAdd className='text-2xl ml-3'/>
        </Button>
      }
      {showAdd && (
        <div className='mb-6'>
          <AddClass cancel={() => setShowAdd(false)}/>
        </div>
      )}
      <Table striped>
        <Table.Head>
          <Table.HeadCell>Класс</Table.HeadCell>
          <Table.HeadCell>Смена</Table.HeadCell>
          <Table.HeadCell className=' text-right'></Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {sortedClasses && sortedClasses.map((classObj) => (
            <Table.Row key={classObj.id} className='bg-white dark:border-gray-700 dark:bg-gray-800'>
              <Table.Cell className='w-[calc=100% - 200px] text-xl text-black'>
                {editClassRoomId === classObj.id ? (
                  <>
                    <EditClass
                      editClassRoomId={editClassRoomId}
                      nameValue={editName}
                      letterValue={editLetter}
                      shiftValue={editShift}
                      onClose={() => startEdit(null)}
                    />
                  </>
                ) : (
                  <div className='font-medium'>
                    {classObj.attributes.name} - <span>
                      {classObj.attributes.letter.length > 1 ? classObj.attributes.letter.charAt(0).toUpperCase() + classObj.attributes.letter.slice(1) : classObj.attributes.letter.toUpperCase()}</span>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className='text-xl text-black'>
                {editClassRoomId !== classObj.id &&
                  <div className='flex'>
                    {classObj.attributes.shift === 'first' ? <Badge color='success' size='sm'>I смена</Badge> : <Badge color='info' size='sm'>II смена</Badge>}
                  </div>
                }
              </Table.Cell>
              <Table.Cell className='w-[200px]'>
                {editClassRoomId === classObj.id ? (
                  <MdClose className='cursor-pointer hover:text-blue-600 transition' title='Закрыть'
                    onClick={() => setEditClassRoomId(false)}/>
                ) : (
                  <div className='flex gap-4 justify-end'>
                    <RiEdit2Line
                      className='cursor-pointer hover:text-blue-600 transition text-xl'
                      title='Редактировать'
                      onClick={() => startEdit(classObj)}
                    />
                    <RiDeleteBinLine
                      className='cursor-pointer hover:text-red-600 transition text-xl'
                      title='Удалить'
                      onClick={() => openDeleteFunction(classObj.id)}
                    />
                    <Modal show={openDeleteModal} size='md'
                      onClose={() => setOpenDeleteModal(false)} popup>
                      <Modal.Header/>
                      <Modal.Body>
                        <div className='text-center'>
                          <HiOutlineExclamationCircle
                            className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                          <div className='mb-5 text-lg font-normal'>
                            Вы точно хотите удалить ?
                          </div>
                          <div className='flex justify-center gap-4'>
                            <Button color='failure'
                              onClick={() => deleteRoom(deletedItemId)}>
                              Удалить
                            </Button>
                            <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                              Отмена
                            </Button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

import {Tabs} from 'flowbite-react';
import {HiAdjustments, HiCalendar, HiMailOpen, HiNewspaper} from 'react-icons/hi';

import SchedulePage from './schedule/SchedulePage';
import NewsPage from './news/NewsPage';
import MessagePage from './message/MessagePage';
import SettingsPage from './settings/SettingsPage';

export default function TabsBlock({schoolID}) {
  return (
    <>
      <div className='container mx-auto px-4 pb-10'>
        <Tabs
          aria-label='Default tabs'
          className='default gap-6'
        >
          <Tabs.Item
            active
            icon={HiCalendar}
            title='Расписания'
          >
            <SchedulePage schoolID={schoolID}/>
          </Tabs.Item>
          <Tabs.Item
            title='Новости'
            icon={HiNewspaper}
          >
            <NewsPage schoolID={schoolID}/>
          </Tabs.Item>
          <Tabs.Item
            title='Сообщения'
            icon={HiMailOpen}
          >
            <MessagePage schoolID={schoolID}/>
          </Tabs.Item>
          <Tabs.Item
            title='Настройки'
            icon={HiAdjustments}
          >
            <SettingsPage schoolID={schoolID}/>
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  );
}

import {gql, useQuery} from '@apollo/client';

import Header from '../components/HeaderBlock';
import TabsBlock from './TabsBlock';
import React, {useEffect, useState} from 'react';
import Loader from '../components/Loader';
import {ErrorHandling} from '../components/ErrorHandling';
import VersionAndDeveloper from '../components/VersionAndDeveloper';

const INSTITUTION = gql`
    query{
        usersPermissionsUser(
            id: 0
        ){
            data{
                attributes{
                    name
                    email
                    institution{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export default function HomePage() {
  const {data, error, loading} = useQuery(INSTITUTION);
  const [schoolID, setSchoolID] = useState('');

  useEffect(() => {
    if (data) {
      let id = data?.usersPermissionsUser?.data?.attributes?.institution?.data?.id;
      if (Number.isInteger(+id)) {
        localStorage.setItem('schoolID', id);
        setSchoolID(id);
      }
    }
  }, [data, schoolID]);

  if (schoolID === '') return <Loader/>;

  return (
    <>
      {loading && <Loader/>}
      <ErrorHandling error={[error]}/>
      <Header schoolID={schoolID}/>
      <TabsBlock schoolID={schoolID}/>
      <VersionAndDeveloper/>
    </>
  );
}

import React, {useMemo, useState} from 'react';
import {Table} from 'flowbite-react';
import {RiEdit2Line} from 'react-icons/ri';
import {MdClose} from 'react-icons/md';
import {useQuery} from '@apollo/client';

import {SETTINGS_LIST} from './gql';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';
import EditSettingReturnToMainScreen from './ReturnToMainEdit';

export const SettingReturnToMainScreen = 'ReturnToMainScreen';

export default function ReturnToMainView() {
  const [editingSettingReturnToMainScreen, setEditingSettingReturnToMainScreen] = useState(false);

  const {data, loading: loadingList, error} = useQuery(SETTINGS_LIST, {
    variables: {
      filters: {institution: {id: {eq: localStorage.getItem('schoolID')}}},
      pagination: {limit: 500},
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  const settingReturnToMainScreen = useMemo(() => {
    const def = {
      id: undefined,
      value: 15,
    };

    const settings = data?.settings?.data;
    if (!settings) {
      return def;
    }

    const foundItem = settings.find((setting => setting.attributes.name === SettingReturnToMainScreen));
    if (foundItem === undefined) {
      return def;
    }

    return {
      id: foundItem.id,
      value: foundItem.attributes.val,
    };
  }, [data]);

  return (
    <>
      {loadingList && (
        <Table.Row><Table.Cell className='text-black' colSpan={3}><Loader/></Table.Cell></Table.Row>
      )}
      {error && (
        <Table.Row><Table.Cell className='text-black' colSpan={3}><ErrorHandling error={[error]}/></Table.Cell></Table.Row>
      )}

      <Table.Row key='settings' className='bg-white dark:border-gray-700 dark:bg-gray-800 relative'>
        <Table.Cell className='text-black'>
          <div >
          Время ожидания (в секундах) перед возвратом на главный экран,
          если пользователь не использует монитор в холле.
          </div>
        </Table.Cell>
        <Table.Cell className='w-[300px] text-xl text-black'>
          {editingSettingReturnToMainScreen &&
          <EditSettingReturnToMainScreen id={settingReturnToMainScreen.id} value={settingReturnToMainScreen.value}
            onSave={() => setEditingSettingReturnToMainScreen(false)}/>
          }
          {!editingSettingReturnToMainScreen && <div className='font-medium'> {settingReturnToMainScreen.value} </div>}
        </Table.Cell>
        <Table.Cell className='w-[50px]'>
          <div className='flex gap-4 justify-end'>
            {!editingSettingReturnToMainScreen && <RiEdit2Line
              className='cursor-pointer hover:text-blue-600 transition text-xl'
              title='Редактировать'
              onClick={() => {
                setEditingSettingReturnToMainScreen(true);
              }}
            />}
            {editingSettingReturnToMainScreen &&
            <MdClose className='cursor-pointer hover:text-blue-600 transition' title='Закрыть'
              onClick={() => setEditingSettingReturnToMainScreen(false)}/>}
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
}
import React, {useState} from 'react';
import {Button, TextInput} from 'flowbite-react';
import {gql, useApolloClient, useMutation} from '@apollo/client';

import {ErrorNetworkDefault} from '../../../components/ErrorHandling';
import formatText from '../../../utils/FormatText';
import Loader from '../../../components/Loader';

const UPDATE_CLASSROOM = gql`
    mutation updateClassroom($data: ClassroomInput!, $id: ID!) {
        updateClassroom(data: $data, id: $id) {
            data {
                attributes {
                    name
                }
            }
        }
    }
`;

const GET_CLASSROOM = gql`
    query getClassRoom($filters: ClassroomFiltersInput) {
        classrooms(
            filters: $filters,
            pagination: {
                limit: 1
            }) {
            data {
                attributes {
                    name
                    number
                }
                id
            }
        }
    }
`;

export default function EditClassRoomsData({editClassRoomId, name, number, onSave}) {
  const [newEditNameError, setNewEditNameError] = useState('');
  const [editName, setEditName] = useState(name);
  const [editNumber, setEditNumber] = useState(number);

  const client = useApolloClient();
  const [updateClassroom, {loading}] = useMutation(UPDATE_CLASSROOM);

  const saveClassroom = async () => {
    setNewEditNameError('');

    if (editName.trim() === '' || editNumber.trim() === '') {
      setNewEditNameError('Название кабинета и номер кабинета не могут быть пустые');
      return;
    }

    try {
      const {data: old} = await client.query({
        query: GET_CLASSROOM,
        variables: {
          filters: {
            number: {eq: editNumber.trim()},
            institution: {id: {eq: localStorage.getItem('schoolID')}},
          },
        },
        fetchPolicy: 'no-cache',
        skip: localStorage.getItem('schoolID') === '',
      });
      if (old?.classrooms?.data.length > 0 && old?.classrooms?.data[0].id !== editClassRoomId) {
        setNewEditNameError('Кабинет с таким номером уже существует');
        return;
      }

      await updateClassroom({
        variables: {
          id: editClassRoomId,
          data: {
            name: editName.charAt(0).toUpperCase() + editName.slice(1), // Принудительная капитализация
            number: editNumber.trim(),
          },
        },
        refetchQueries: ['classrooms'],
      });
      onSave();
    } catch (error) {
      setNewEditNameError(ErrorNetworkDefault + 'Error:' + error);
      setTimeout(()=>{ setNewEditNameError(''); }, 3000);
    }
  };

  const handleNameChange = (event) => {
    setNewEditNameError('');
    const inputValue = event.target.value;
    const formattedValue = formatText(inputValue);

    setEditName(formattedValue);
  };

  const handleNumberChange = (event) => {
    setNewEditNameError('');
    const inputValue = event.target.value;
    const formattedValue = formatText(inputValue);

    setEditNumber(formattedValue);
  };

  return (
    <div className='flex justify-between'>
      {loading && (<Loader/>)}
      <TextInput
        className='w-[400px] mr-4'
        value={editNumber}
        onChange={handleNumberChange}
        placeholder='Номер кабинета'
      />
      <TextInput
        className='w-[400px] mr-4'
        value={editName}
        onChange={handleNameChange}
        placeholder='Название кабинета'
      />
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newEditNameError) && <span className='font-medium'> {newEditNameError}</span>}
      </p>
      <Button onClick={saveClassroom}>Сохранить</Button>
    </div>
  );
}

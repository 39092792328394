import {gql} from '@apollo/client';

export const SCHEDULE_LIST = gql`
    query schedules($pagination: PaginationArg, $filters: ScheduleFiltersInput) {
        schedules(pagination: $pagination, filters: $filters) {
            data {
                attributes {
                    subject{
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    day
                    schedule_time{
                        data {
                            attributes {
                                timeStart
                                timeEnd
                            }
                            id
                        }
                    }
                    schedule_name {
                        data {
                            id
                        }
                    }
                    classroom{
                        data {
                            attributes{
                                name
                                number
                                subject{
                                    data {
                                        attributes {
                                            name
                                        }
                                    }
                                }
                            }
                            id
                        }
                    }
                    teacher {
                        data {
                            attributes{
                                name
                            }
                            id
                        }
                    }
                }
                id
            }
        }
    }
`;

export const SCHEDULE_TIMES = gql`
    query scheduleTimes($pagination: PaginationArg = {}, $filters: ScheduleTimeFiltersInput, $sort: [String] = []) {
        scheduleTimes(pagination: $pagination, filters: $filters, sort: $sort) {
            data {
                attributes {
                    timeStart
                    timeEnd
                    day
                }
                id
            }
        }
    }
`;

export const SCHEDULES_NAME = gql`
    query scheduleNames($pagination: PaginationArg = {}, $filters: ScheduleNameFiltersInput) {
        scheduleNames(pagination: $pagination, filters: $filters, publicationState: PREVIEW) {
            data {
                attributes {
                    class {
                        data {
                            attributes {
                                letter
                                shift
                                name
                            }
                        }
                    }
                    end
                    start
                    publishedAt
                }
                id
            }
        }
    }
`;

export const UPDATE_SCHEDULE_NAME = gql`
    mutation updateScheduleName($data: ScheduleNameInput!, $id: ID!) {
        updateScheduleName(data: $data, id: $id) {
            data {
                attributes {
                    publishedAt
                    start
                    end
                }
                id
            }
        }
    }
`;

import {HiOutlineExclamationCircle} from 'react-icons/hi';
import formatDateTime from '../../utils/FormatDateTime';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {Button, Modal} from 'flowbite-react';
import {useState} from 'react';
import EditMessageData from './EditMessageData';

export default function MessageCard({data, deleteNews}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [showEditSlot, setShowEditSlot] = useState(false);
  const [messageItemData, setMessageItemData] = useState({});

  const deleteMethod = (id) => {
    deleteNews(id);
    setDeletedItemId(id);
    setOpenDeleteModal(false);
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  const editSubject = (item) => {
    setShowEditSlot(!showEditSlot);
    setMessageItemData(item);
  };

  return (
    <>
      {data.map((message, index) => (
        <div className='flex mb-6' key={index}>
          <div className='w-[20px]'>{index + 1}.</div>
          <div className='grow px-5'>
            <div className='text-gray-700'>{formatDateTime(message.newsDate)}</div>
            <div className='text-xl font-bold'>{message.newsTitle}</div>
            {message.newsDescr}
          </div>
          <div className='flex gap-4 justify-end'>
            <RiEdit2Line
              className='cursor-pointer hover:text-blue-600 transition text-xl'
              title='Редактировать'
              onClick={() => editSubject(message)}
            />
            <Modal show={showEditSlot} size='lg' onClose={() => setShowEditSlot(false)} popup>
              <Modal.Header/>
              <Modal.Body>
                <EditMessageData data={messageItemData} onCancel={() => setShowEditSlot(false)}/>
              </Modal.Body>
            </Modal>
            <RiDeleteBinLine
              className='cursor-pointer hover:text-red-600 transition text-xl'
              title='Удалить'
              onClick={() => openDeleteFunction(message.newsId)}
            />
            <Modal show={openDeleteModal} size='md' onClose={() => setOpenDeleteModal(false)} popup>
              <Modal.Header/>
              <Modal.Body>
                <div className='text-center'>
                  <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                  <div className='mb-5 text-lg font-normal'>
                    Вы точно хотите удалить ?
                  </div>
                  <div className='flex justify-center gap-4'>
                    <Button color='failure' onClick={() => deleteMethod(deletedItemId)}>
                      Удалить
                    </Button>
                    <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                      Отмена
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ))}
    </>
  );
}

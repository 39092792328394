import {HiOutlineExclamationCircle} from 'react-icons/hi';
import formatDateTime from '../../utils/FormatDateTime';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import {Button, Modal} from 'flowbite-react';
import {useState} from 'react';
import EditNewsData from './EditNewsData';

export default function NewsCard({news, deleteNews}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [showEditSlot, setShowEditSlot] = useState(false);
  const [messageItemData, setMessageItemData] = useState({});

  const deleteMethod = (id) => {
    deleteNews(id);
    setDeletedItemId(id);
    setOpenDeleteModal(false);
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  const editSubject = (item) => {
    setShowEditSlot(!showEditSlot);
    setMessageItemData(item);
  };

  return (
    <>
      {news.map((news, index) => (
        <div className='flex mb-6' key={index}>
          <div className='w-[20px]'>{index + 1}.</div>
          <div className='w-[25%] font-bold'><small
            className='block text-gray-600 font-normal'>{formatDateTime(news.newsDate)}</small>{news.newsTitle}
          </div>
          <div className='grow max-w-[40%] px-4'>{news.newsDescr}</div>
          <a href={news.newsLink} target='_blank' rel='noopener noreferrer'
            className='w-[20%] px-4 hover:text-cyan-700 transition'>{news.newsLink}</a>
          <div className='flex gap-4 justify-end'>
            <RiEdit2Line
              className='cursor-pointer hover:text-blue-600 transition text-xl'
              title='Редактировать'
              onClick={() => editSubject(news)}
            />
            <Modal show={showEditSlot} size='lg' onClose={() => setShowEditSlot(false)} popup>
              <Modal.Header/>
              <Modal.Body>
                <EditNewsData data={messageItemData} onCancel={() => setShowEditSlot(false)}/>
              </Modal.Body>
            </Modal>
            <RiDeleteBinLine
              className='cursor-pointer hover:text-red-600 transition text-xl'
              title='Удалить'
              onClick={() => openDeleteFunction(news.newsId)}
            />
            <Modal show={openDeleteModal} size='md' onClose={() => setOpenDeleteModal(false)} popup>
              <Modal.Header/>
              <Modal.Body>
                <div className='text-center'>
                  <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                  <div className='mb-5 text-lg font-normal'>
                    Вы точно хотите удалить ?
                  </div>
                  <div className='flex justify-center gap-4'>
                    <Button color='failure' onClick={() => deleteMethod(deletedItemId)}>
                      Удалить
                    </Button>
                    <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                      Отмена
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ))}
    </>
  );
}

import React, {useEffect, useState} from 'react';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import {onError} from '@apollo/client/link/error';
import Loader from './components/Loader';

const WithApolloClient = ({children}) => {
  const [token, setToken] = useState(localStorage.getItem('token')); // Извлекаем токен из localStorage
  const [client, setClient] = useState(null);

  useEffect(() => {
    const handleStorageChange = () => {
      // Обновляем токен при каждом изменении в localStorage
      setToken(localStorage.getItem('token'));
    };

    // Подписываемся на события изменения localStorage
    window.addEventListener('storage', handleStorageChange);

    // Отписываемся, когда компонент будет размонтирован
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_BACKEND_URL ?? 'https://api.vvsrv.ru/graphql',
    });

    const authLink = setContext((_, {headers}) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '', // Используем токен из состояния
        },
      };
    });

    const errorLink = onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError && networkError.statusCode === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    });

    const newClient = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache(),
    });

    setClient(newClient);
  }, [token]); // Пересоздаем клиента при изменении токена

  if (!client) return <Loader/>;

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default WithApolloClient;

import {gql, useMutation, useQuery} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import {Badge, Button, Modal, Table} from 'flowbite-react';
import {MdAdd} from 'react-icons/md';
import {RiDeleteBinLine, RiEdit2Line} from 'react-icons/ri';
import AddSubject from './AddSubject';
import EditSubjectData from './EditSubjectData';
import {HiOutlineExclamationCircle} from 'react-icons/hi';
import Loader from '../../../components/Loader';
import {ErrorHandling} from '../../../components/ErrorHandling';

const SUBJECTS_LIST = gql`
    query subjects($filters: SubjectFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        subjects(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    classrooms {
                        data {
                            attributes {
                                name
                                number
                            }
                            id
                        }
                    }
                    teachers {
                        data {
                            attributes {
                                name
                            }
                            id
                        }
                    }
                }
                id
            }
        }
    }
`;

const DELETE_SUBJECT = gql`
    mutation updateSubject($data: SubjectInput!, $id: ID!) {
        updateSubject(data: $data, id: $id) {
            data {
                attributes {
                    name
                    publishedAt
                }
            }
        }
    }
`;

const getSortedSubjects = (subjectsData) => {
  return subjectsData?.slice().sort((a, b) => {
    const nameA = a.attributes.name.toUpperCase(); // для нечувствительности к регистру
    const nameB = b.attributes.name.toUpperCase();

    return nameA.localeCompare(nameB);
  });
};

export default function SubjectsList() {
  const [showAdd, setShowAdd] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [showEditSlot, setShowEditSlot] = useState(false);
  const [subjectItemData, setSubjectItemData] = useState({});

  const {data, loading, error, refetch} = useQuery(SUBJECTS_LIST, {
    variables: {
      pagination: {limit: 500},
      filters: {
        institution: {
          id: {  eq: localStorage.getItem('schoolID')},
        },
        publishedAt: {ne: null},
      },
    },
    skip: localStorage.getItem('schoolID') === '',
  });
  const [deleteSubject, {error: errorDelete, loading: loadingDelete}] = useMutation(DELETE_SUBJECT);

  const sortedSubjects = useMemo(()=>{
    if (!data?.subjects?.data) {
      return [];
    }

    return getSortedSubjects(data?.subjects?.data);
  }, [data?.subjects?.data]);
  
  const updateList = () => {
    setShowAdd(false);
  };

  const deleteRoom = async (subject) => {
    try {
      await deleteSubject({
        variables: {
          id: subject,
          data: {
            publishedAt: null,
          },
        },
        refetchQueries: ['subjects'],
      });
    } catch (error) {
      console.error('Ошибка при удалении предмета', error);
    }

    setOpenDeleteModal(false);
  };

  const openDeleteFunction = (id) => {
    setDeletedItemId(id);
    setOpenDeleteModal(true);
  };

  const editSubject = (item) => {
    setShowEditSlot(!showEditSlot);
    setSubjectItemData(item);
  };

  if (loading) return <Loader/>;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <>
      {loadingDelete && <Loader/>}
      <ErrorHandling error={[errorDelete, error]}/>
      <ErrorHandling error={error}/>
      {!showAdd &&
        <Button className='mb-6' onClick={() => setShowAdd(true)}>
          Добавить новый предмет
          <MdAdd className='text-2xl ml-3'/>
        </Button>
      }
      {showAdd && (
        <div className='mb-6'>
          <AddSubject refetch={updateList} cancel={() => setShowAdd(false)}/>
        </div>
      )}
      <Table striped>
        <Table.Head>
          <Table.HeadCell>Предмет</Table.HeadCell>
          <Table.HeadCell>Учитель</Table.HeadCell>
          <Table.HeadCell>Кабинет</Table.HeadCell>
          <Table.HeadCell></Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {sortedSubjects && sortedSubjects.map((subjectObj) => (
            <Table.Row key={subjectObj.id} className='bg-white dark:border-gray-700 dark:bg-gray-800'>
              <Table.Cell
                className='w-[30%] text-xl text-black'> {subjectObj.attributes.name}</Table.Cell>
              <Table.Cell className='w-[45%]'>
                <div className='flex flex-wrap gap-2'>
                  {[...subjectObj.attributes?.teachers?.data]
                    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
                    .map(teacher => (
                      <Badge key={teacher.attributes.name} className='text-black' size='md'
                        color='info'>
                        {teacher.attributes.name}
                      </Badge>
                    ))}
                </div>
              </Table.Cell>
              <Table.Cell className='w-[20%]'>
                <div className='flex flex-wrap gap-2'>
                  {subjectObj.attributes?.classrooms?.data.map(classroom => (
                    <Badge key={classroom.attributes.name} className='text-black whitespace-nowrap' size='md'
                      color='info'>
                      {classroom.attributes.name}
                      {!isNaN(classroom.attributes.number) ? <span> (№ {classroom.attributes.number})</span> :
                        <span> ({classroom.attributes.number})</span>}
                    </Badge>
                  ))}
                </div>
                <Modal show={showEditSlot} size='lg' onClose={() => setShowEditSlot(false)} popup>
                  <Modal.Header/>
                  <Modal.Body>
                    <EditSubjectData itemData={subjectItemData}
                      onCancel={() => setShowEditSlot(false)}
                      refetch={() => refetch()}/>
                  </Modal.Body>
                </Modal>
              </Table.Cell>
              <Table.Cell className='w-[200px]'>
                <div className='flex gap-4 justify-end'>
                  <RiEdit2Line
                    className='cursor-pointer hover:text-blue-600 transition text-xl'
                    title='Редактировать'
                    onClick={() => editSubject(subjectObj)}
                  />
                  <RiDeleteBinLine
                    className='cursor-pointer hover:text-red-600 transition text-xl'
                    title='Удалить'
                    onClick={() => openDeleteFunction(subjectObj.id)}
                  />
                  <Modal show={openDeleteModal} size='md' onClose={() => setOpenDeleteModal(false)}
                    popup>
                    <Modal.Header/>
                    <Modal.Body>
                      <div className='text-center'>
                        <HiOutlineExclamationCircle
                          className='mx-auto mb-4 h-14 w-14 text-red-500'/>
                        <div className='mb-5 text-lg font-normal'>
                          Вы точно хотите удалить ?
                        </div>
                        <div className='flex justify-center gap-4'>
                          <Button color='failure' onClick={() => deleteRoom(deletedItemId)}>
                            Удалить
                          </Button>
                          <Button color='gray' onClick={() => setOpenDeleteModal(false)}>
                            Отмена
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

import React, {useEffect, useMemo, useState} from 'react';
import {Button} from 'flowbite-react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {ErrorHandling, ErrorNetworkDefault} from '../../components/ErrorHandling';
import SelectInput from '../../components/SelectInput';
import Loader from '../../components/Loader';

const CLASSES_LIST = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg = {} $sort: [String] = []) {
        classes(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                attributes {
                    name
                    letter
                }
                id
            }
        }
    }
`;

const CREATE_SCHEDULE_NAME = gql`
    mutation createScheduleName($data: ScheduleNameInput!) {
        createScheduleName(data: $data) {
            data {
                attributes {
                    start
                    end
                }
                id
            }
        }
    }
`;

function dateToStrDate(date) {
  const timeZoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() - timeZoneOffset);

  return adjustedDate.toISOString().split('T')[0];
}

// Функция для получения уникальных значений
const getUniqueValues = (data, key) => {
  const uniqueSet = new Set();
  return data
    .filter(item => {
      const value = item.attributes[key];
      if (uniqueSet.has(value)) {
        return false;
      } else {
        uniqueSet.add(value);
        return true;
      }
    })
    .map(item => ({
      value: item.attributes[key],
      label: item.attributes[key],
    }));
};

export default function AddNewSchedule({onClose}) {
  const [newCommonError, setNewCommonError] = useState('');
  const [createScheduleName, {loading: loadingCreate}] = useMutation(CREATE_SCHEDULE_NAME);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);
  const [classNumber, setClassNumber] = useState('');
  const [classLetter, setClassLetter] = useState('');

  const {data: classNumberData, loading: loadingNumberData, errorNumberData} = useQuery(CLASSES_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
      },
      pagination: { limit: 3000 },
      sort: ['name'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  const {data: classLetterData, loading: loadingLetterData, error: errorLetterData} = useQuery(CLASSES_LIST, {
    variables: {
      filters: {
        institution: {id: {eq: localStorage.getItem('schoolID')}},
        name: {eq: classNumber},
      },
      pagination: { limit: 3000 },
      sort: ['letter'],
    },
    skip: localStorage.getItem('schoolID') === '',
  });

  // Получение уникальных номеров и букв классов
  const numberListForSelect = useMemo(() => {
    return classNumberData ? getUniqueValues(classNumberData.classes.data, 'name').sort((a, b) => Number(a.value) - Number(b.value)) : [];
  }, [classNumberData]);

  const letterListForSelect = useMemo(() => {
    return classLetterData ? getUniqueValues(classLetterData.classes.data, 'letter').map(item => ({
      value: item.value,
      label: item.value.length > 1 ? item.value.charAt(0).toUpperCase() + item.value.slice(1) : item.value.toUpperCase(),
    })) : [];
  }, [classLetterData]);

  useEffect(() => {
    if (letterListForSelect.length > 0) {
      setClassLetter(letterListForSelect[0].value);
    }
  }, [classLetterData, letterListForSelect]);

  const handleCreateSchedule = async () => {
    const startDateStr = dateToStrDate(new Date(startDate));
    const endDateStr = dateToStrDate(new Date(endDate));

    if (startDateStr > endDateStr) {
      setNewCommonError('Дата окончания не может быть раньше даты начала');
      return;
    }

    // Ищем класс, который соответствует выбранному номеру и букве
    const selectedClass = classNumberData.classes.data.find(c => {
      return c.attributes.name === classNumber && c.attributes.letter === classLetter;
    });

    if (!selectedClass) {
      setNewCommonError('Класс не найден');
      return;
    }

    try {
      // Вызываем мутацию с id выбранного класса
      await createScheduleName({
        variables: {
          data: {
            institution: localStorage.getItem('schoolID'),
            class: selectedClass.id,
            start: startDate,
            end: endDate,
          },
        },
        refetchQueries: ['schedules', 'scheduleTimes', 'scheduleNames'],
      });

      onClose();
    } catch (error) {
      setNewCommonError(ErrorNetworkDefault + ' Error:' + error);
      setTimeout(()=>{ setNewCommonError(''); }, 3000);
    }
  };

  const handleStartDateChange = (date) => {
    setNewCommonError('');
    const startDateStr = dateToStrDate(date);
    setStartDate(startDateStr);
  };

  const handleEndDateChange = (date) => {
    setNewCommonError('');
    const endDateStr = dateToStrDate(date);
    setEndDate(endDateStr);
  };

  useEffect(() => {
    if (classNumberData) {
      const uniqueNumbers = getUniqueValues(classNumberData.classes.data, 'name');
      const uniqueLetters = getUniqueValues(classNumberData.classes.data, 'letter');

      if (uniqueNumbers.length > 0) {
        setClassNumber(uniqueNumbers[0].value);
      }

      if (uniqueLetters.length > 0) {
        setClassLetter(uniqueLetters[0].value);
      }
    }
  }, [classNumberData]);

  return (
    <>
      <ErrorHandling error={[errorNumberData, errorLetterData]}/>
      <div className='flex align-bottom gap-4'>
        <div>
          Начало
          <SelectInput onSelectedDateChanged={handleStartDateChange} datapicker={true} minDate={new Date()}/>
        </div>
        <div>
          Конец
          <SelectInput onSelectedDateChanged={handleEndDateChange} datapicker={true} minDate={new Date()}/>
        </div>
        <div>
          Номер класса
          {loadingNumberData && <Loader/>}
          {!loadingNumberData &&
            <SelectInput onChange={(e) => setClassNumber(e.target.value)} options={numberListForSelect}/>}
        </div>
        <div>
          Буква класса
          {loadingLetterData && <Loader/>}
          {!loadingLetterData &&
            <SelectInput onChange={(e) => setClassLetter(e.target.value)} options={letterListForSelect}/>}
        </div>
      </div>
      <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
        {(newCommonError) &&
          <span className='font-medium'> {newCommonError}</span>}
      </p>
      <div className='flex gap-6 mt-6'>
        <Button color='gray' onClick={onClose}>Отмена</Button>
        <Button
          onClick={handleCreateSchedule}
          isProcessing={loadingCreate}
          disabled={!startDate || !endDate}
        >
          Добавить
        </Button>
      </div>
    </>
  );
}

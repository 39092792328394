import {TextInput} from 'flowbite-react';
import React from 'react';

export default function EditableTimeSlot({lessonId, lesson, index, onTimeChange}) {
  const timeStart = lesson.timeStart === '00:00:00.000' ? null : lesson.timeStart;
  const timeEnd = lesson.timeEnd === '00:00:00.000' ? null : lesson.timeEnd;

  return (
    <>
      <div className='flex items-center mb-4'>
        <span className='inline-block mr-4 w-[80px]'>Урок {index + 1}: </span>
        <div className='block'>
          <div className='text-xs mb-1'>Начало</div>
          <TextInput
            className='w-[200px]'
            type='time'
            value={timeStart || ''}
            onChange={(e) => onTimeChange(e.target.value, 'timeStart')}
          />
        </div>
        <span className='inline-block px-2'>-</span>
        <div className='block'>
          <div className='text-xs mb-1'>Конец</div>
          <TextInput
            className='w-[200px]'
            type='time'
            value={timeEnd || ''}
            onChange={(e) => onTimeChange(e.target.value, 'timeEnd')}
          />
        </div>
      </div>
    </>
  );
}


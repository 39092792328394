export default function formatDateTime(dateString, time = true) {
  const date = new Date(dateString);

  // Опции для форматирования даты и времени
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,  // Для использования 24-часового формата
  };
  if (!time) {
    options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  }

  // Возвращаем дату и время в заданном формате с учетом локали
  return date.toLocaleString('ru-RU', options);
}

